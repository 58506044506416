import React, { useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Button, Col, Form, Row } from "react-bootstrap";
import { ParentSize } from "@visx/responsive";
import {
  useQueryParams,
  withDefault,
  StringParam,
  BooleanParam,
} from "use-query-params";

import AppContext from "../../shared/AppContext";
import {
  ActionRoleStats,
  LeagueImpact,
} from "../../shared/routers/ImpactRouter";
import { LeagueSeasonStats } from "../../shared/routers/LeagueRouter";
import { Page } from "../components/core/Page";
import { Panel } from "../components/core/Panel";
import { PlayerRankedStatTable } from "../components/player/PlayerRankedStatTable";
import {
  statLabelMap,
  actionRolesOff,
  actionRolesDef,
} from "../constants/AppConstants";
import { trpc } from "../util/tRPC";
import { SwarmChart } from "../components/chart/SwarmChart";
import { colorFromPosition } from "../util/Colors";
import {
  dec100Format,
  decFormat2,
  decFormat,
  decFormat3,
  seFormat,
} from "../util/Format";

export function StatsPage() {
  const { stat, actionRole } = useParams();
  const [queryParams, setQueryParams] = useQueryParams({
    season: withDefault(StringParam, AppContext.currentSeason),
    minThreshold: withDefault(BooleanParam, true),
  });
  const { season, minThreshold } = queryParams;

  const navigate = useNavigate();
  const seasons = AppContext.seasons;

  const { data: seasonStats } = trpc.league.getLeagueSeasonStats.useQuery({
    season,
  });

  const { data: impact } = trpc.impact.getLeagueImpact.useQuery({
    salaryAndAge: true,
    season,
  });

  const { data: actionRoleStats } = trpc.impact.getActionRoleStats.useQuery({
    season,
  });

  if (!stat) {
    navigate("/stats/pgg");
    return null;
  }

  const isActionRoleStat = stat.includes("actionRole");
  const isImpactStat = stat.includes("Impact") && !isActionRoleStat;

  if (isActionRoleStat && !actionRole) {
    // If ability or role is selected default to the first non-apm thing (which
    // has idx 1) otherwise just use idx = 0.
    const isAbilityOrRate =
      stat === "actionRoleAbility" || stat === "actionRoleRate";

    const defaultIdx = isAbilityOrRate ? 1 : 0;

    navigate(
      `/stats/${stat}/${
        actionRolesOff[defaultIdx]
      }/?season=${season}&minThreshold=${minThreshold ? 1 : 0}`
    );
    return null;
  }

  const statLabels: Record<string, { longLabel: string; shortLabel: string }> =
    Object.assign({}, statLabelMap, {
      netImpact: { longLabel: "Impact", shortLabel: "Impact" },
      offImpact: { longLabel: "Offensive Impact", shortLabel: "Impact-Off" },
      defImpact: { longLabel: "Defensive Impact", shortLabel: "Impact-Def" },
      actionRoleImpact: {
        longLabel: "Action Role Impact",
        shortLabel: "Action Role Impact",
      },
      actionRoleAbility: {
        longLabel: "Action Role Ability",
        shortLabel: "Action Role Ability",
      },
      actionRoleRate: {
        longLabel: "Action Role Rate",
        shortLabel: "Action Role Rate",
      },
    });

  const handleSeasonChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    setQueryParams({ season: evt.target.value });
  };

  const shouldDisableOption = (ar: string) => {
    const isAbilityOrRate =
      stat === "actionRoleAbility" || stat === "actionRoleRate";
    return isAbilityOrRate && ar.includes("APM");
  };

  const selectedStat = statLabels[stat];

  if (!selectedStat) {
    navigate("/error");
    return null;
  }

  const header = (
    <div>
      <div
        style={{
          padding: 8,
          display: "inline-block",
        }}
      >
        <label>Season</label>
        <Form.Select
          style={{
            width: "auto",
            marginLeft: "1rem",
            display: "inline-block",
          }}
          value={season}
          onChange={handleSeasonChange}
        >
          {seasons.map((season) => (
            <option key={season.value} value={season.value}>
              {season.label}
            </option>
          ))}
        </Form.Select>
      </div>
      {isActionRoleStat && (
        <div
          style={{
            padding: 8,
            display: "inline-block",
          }}
        >
          <label>Action Role</label>
          <Form.Select
            style={{
              width: "auto",
              marginLeft: "1rem",
              display: "inline-block",
            }}
            value={actionRole}
            onChange={(e) =>
              navigate(
                `/stats/${stat}/${
                  e.target.value
                }?season=${season}&minThreshold=${minThreshold ? 1 : 0}`
              )
            }
          >
            <optgroup label={"Offense"}>
              {actionRolesOff.map((ar) => (
                <option key={ar} value={ar} disabled={shouldDisableOption(ar)}>
                  {ar}
                </option>
              ))}
            </optgroup>
            <optgroup label={"Defense"}>
              {actionRolesDef.map((ar) => (
                <option key={ar} value={ar} disabled={shouldDisableOption(ar)}>
                  {ar}
                </option>
              ))}
            </optgroup>
          </Form.Select>
        </div>
      )}
      <h1>{selectedStat.longLabel}</h1>
      <ul
        style={{
          marginTop: 10,
          marginBottom: 0,
          marginLeft: -5,
          maxWidth: 500,
          paddingLeft: 0,
          listStyle: "none",
        }}
      >
        {Object.keys(statLabels).map((key, i) => {
          const curStat = statLabels[key];
          const statShortLabel = curStat ? curStat.shortLabel : "";
          return (
            <li
              key={i}
              style={{
                display: "inline-block",
                paddingLeft: 5,
                paddingRight: 5,
              }}
            >
              {[
                "actionRoleImpact",
                "actionRoleAbility",
                "actionRoleRate",
              ].includes(key) ? (
                !(
                  key !== "actionRoleImpact" &&
                  actionRole &&
                  actionRole.includes("APM")
                ) ? (
                  <Link
                    to={`/stats/${key}${
                      actionRole ? `/${actionRole}` : ""
                    }${`?season=${season}&minThreshold=${
                      minThreshold ? 1 : 0
                    }`}`}
                  >
                    {statShortLabel}
                  </Link>
                ) : (
                  <span style={{ cursor: "no-drop" }}>{statShortLabel}</span>
                )
              ) : (
                <Link
                  to={`/stats/${key}${`?season=${season}&minThreshold=${
                    minThreshold ? 1 : 0
                  }`}`}
                >
                  {statShortLabel}
                </Link>
              )}
            </li>
          );
        })}
      </ul>
      <Form.Check
        style={{ marginTop: 8 }}
        type="checkbox"
        label="Only show players with at least 100 NBA min."
        checked={minThreshold}
        onChange={() => setQueryParams({ minThreshold: !minThreshold })}
      />
    </div>
  );

  const positions: Record<number, string> = {
    1: "Point Guards",
    2: "Shooting Guards",
    3: "Small Forwards",
    4: "Power Forwards",
    5: "Centers",
  };

  const dataReady = !!(seasonStats && impact && actionRoleStats);

  const playerIdsMeetingMinThreshold = new Set(
    (seasonStats || [])
      .filter((ss) => ss.minutes >= 100)
      .map((ss) => ss.playerId)
  );

  let statData: {
    name: string;
    playerId: number;
    value: number;
    error?: number;
    salary?: number;
    age?: number;
    position: number;
    valueOverTime?: number[];
  }[] = prepareStatsData(seasonStats || [], stat);

  if (isImpactStat) {
    statData = prepareImpactData(impact || [], stat);
  }
  if (isActionRoleStat && actionRole) {
    statData = prepareActionRoleStatsData(
      actionRoleStats || [],
      stat,
      actionRole
    );
  }

  if (minThreshold) {
    // Filter out players who don't meet the minimum threshold if that is set.
    statData = statData.filter((d) =>
      playerIdsMeetingMinThreshold.has(d.playerId)
    );
  }

  const actionRoleLabel = (actionRole: string, stat: string) => {
    let statType = "Ability";
    if (stat === "actionRoleImpact") {
      statType = "Impact";
    } else if (stat === "actionRoleRate") {
      statType = "Rate";
    }
    return `${actionRole} ${statType}`;
  };

  const label = actionRole
    ? actionRoleLabel(actionRole, stat)
    : selectedStat.shortLabel;

  return (
    <Page title={`${label} Stat Details`} header={{ component: header }}>
      {statData.length > 0 ? (
        <Row>
          <Col lg={7} sm={12}>
            <Panel header="All Players">
              <div>
                <ParentSize parentSizeStyles={{ width: "100%" }}>
                  {({ width }) =>
                    width > 0 && (
                      <SwarmChart
                        height={width / 2}
                        width={width}
                        label={label}
                        format={formatForStat(stat)}
                        data={[...statData]
                          .sort((a, b) => a.position - b.position)
                          .map((sd) => {
                            return {
                              label: sd.name,
                              value: sd.value,
                              color: colorFromPosition(sd.position),
                              stdErr: sd.error,
                            };
                          })}
                        tooltip={tooltipFn(stat)}
                      />
                    )
                  }
                </ParentSize>
                <PlayerRankedStatTable
                  data={statData}
                  stat={stat as keyof LeagueSeasonStats}
                  label={label}
                  season={season}
                />
              </div>
            </Panel>
          </Col>
          <Col lg={5} sm={12}>
            {Object.entries(positions).map(([key, val]) => {
              const data = statData.filter((d) => d.position === parseInt(key));
              return (
                <PositionPanel
                  key={key}
                  val={val}
                  data={data}
                  stat={stat}
                  label={label}
                  season={season}
                />
              );
            })}
          </Col>
        </Row>
      ) : dataReady && statData.length === 0 ? (
        <div>No data is available for the selected season yet.</div>
      ) : null}
    </Page>
  );
}

function PositionPanel(props: {
  val: string;
  data: {
    name: string;
    playerId: number;
    value: number;
    position: number;
    error?: number;
    salary?: number;
    age?: number;
    valueOverTime?: number[];
  }[];
  stat: string;
  label: string;
  season: string;
}) {
  const { data, stat, label, val, season } = props;
  const [showTable, setShowTable] = useState(false);
  const buttonText = showTable ? "Hide Table" : "Show Table";

  const showTableButtonClick = (
    evt: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowTable(!showTable);
    evt.stopPropagation();
  };

  const header = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <span>{val}</span>
      <Button onClick={showTableButtonClick}>{buttonText}</Button>
    </div>
  );
  return (
    <Panel header={header}>
      <div>
        <ParentSize parentSizeStyles={{ width: "100%" }}>
          {({ width }) =>
            width > 0 && (
              <SwarmChart
                height={width / 2}
                width={width}
                label={label}
                format={formatForStat(stat)}
                data={data.map((sd) => {
                  return {
                    label: sd.name,
                    value: sd.value,
                    color: colorFromPosition(sd.position),
                    stdErr: sd.error,
                  };
                })}
                tooltip={tooltipFn(stat)}
              />
            )
          }
        </ParentSize>
        {showTable && (
          <PlayerRankedStatTable
            data={data}
            stat={stat as keyof LeagueSeasonStats}
            label={label}
            season={season}
          />
        )}
      </div>
    </Panel>
  );
}

function prepareImpactData(impact: LeagueImpact[], stat: string) {
  const typedStat = stat as keyof LeagueImpact;
  return impact
    .filter((i) => i[typedStat] !== null)
    .sort((a, b) => {
      return (b[typedStat] as number) - (a[typedStat] as number);
    })
    .map((val) => {
      let error = undefined;
      let valueOverTime = undefined;
      if (stat === "netImpact") {
        error = val.netSE;
        valueOverTime = val.netImpactOverTime;
      } else if (stat === "offImpact") {
        error = val.offSE;
        valueOverTime = val.offImpactOverTime;
      } else if (stat === "defImpact") {
        error = val.defSE;
        valueOverTime = val.defImpactOverTime;
      }

      return {
        name: val.player,
        playerId: val.playerId,
        value: val[typedStat] as number,
        error: error,
        salary: val.totalSalary || 0,
        age: val.age,
        position: val.position,
        valueOverTime: valueOverTime,
      };
    });
}

function prepareStatsData(seasonStats: LeagueSeasonStats[], stat: string) {
  const typedStat = stat as keyof LeagueSeasonStats;
  return seasonStats
    .filter((s) => s[typedStat] !== null)
    .sort((a, b) => {
      return (b[typedStat] as number) - (a[typedStat] as number);
    })
    .map((val) => {
      const hasPer36 = ["ppg", "apg", "rpg", "spg", "bpg", "fg3apg"].includes(
        stat
      );
      return {
        name: [val.firstName, val.lastName].filter((n) => !!n).join(" "),
        playerId: val.playerId,
        value: val[typedStat] as number,
        position: val.position,
        per36: hasPer36
          ? (36 * ((val[typedStat] as number) * val.gp)) / val.minutes
          : undefined,
      };
    });
}

function prepareActionRoleStatsData(
  seasonStats: ActionRoleStats[],
  stat: string,
  role: string
) {
  let statType: keyof ActionRoleStats = "ability";
  if (stat === "actionRoleImpact") {
    statType = "impact";
  } else if (stat === "actionRoleRate") {
    statType = "rate";
  }

  const typedStat = stat as keyof ActionRoleStats;
  return seasonStats
    .filter((s) => s[typedStat] !== null && s.role === role)
    .sort((a, b) => {
      return (b[statType] as number) - (a[statType] as number);
    })
    .map((val) => {
      return {
        name: [val.firstName, val.lastName].filter((n) => !!n).join(" "),
        playerId: val.playerId,
        value: val[statType] as number,
        position: val.position,
      };
    });
}

function formatForStat(stat: string) {
  if (stat === "ppp") {
    return decFormat2;
  } else if (
    stat.indexOf("pct") !== -1 ||
    stat === "crashRate" ||
    stat === "actionRoleRate" ||
    stat === "netImpact" ||
    stat === "defImpact" ||
    stat === "offImpact"
  ) {
    return dec100Format;
  } else {
    return decFormat;
  }
}

function tooltipFn(stat: string) {
  const valueContent = (value: number, stdErr?: number) => {
    const errSuffix = stdErr ? ` ${seFormat(stdErr * 1.96 * 100)}` : "";
    let val;
    if (stat === "netImpact" || stat === "netForecast") {
      return `${dec100Format(value)}${errSuffix}`;
    } else if (stat === "offImpact" || stat === "offForecast") {
      return `${dec100Format(value)}${errSuffix}`;
    } else if (stat === "defImpact" || stat === "defForecast") {
      return `${dec100Format(value)}${errSuffix}`;
    } else if (stat === "ppp") {
      val = decFormat2(value);
    } else if (
      stat.indexOf("pct") !== -1 ||
      stat === "crashRate" ||
      stat === "actionRoleRate"
    ) {
      val = decFormat(value * 100);
    } else if (stat.indexOf("_impact") !== -1) {
      val = decFormat3(value * 100);
    } else if (stat.indexOf("_action") !== -1) {
      val = decFormat3(value * 100);
    } else if (stat.indexOf("_value") !== -1) {
      val = decFormat3(value);
    } else {
      val = decFormat(value);
    }
    return val || "";
  };

  const fn = (d: { value: number; stdErr?: number; label: string }) => {
    return (
      <div>
        <div>
          <b>{d.label}</b>
        </div>
        <div>{valueContent(d.value, d.stdErr)}</div>
      </div>
    );
  };
  return fn;
}
