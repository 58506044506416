import React, { useEffect } from "react";
import { useParams, Navigate } from "react-router-dom";
import {
  useQueryParams,
  StringParam,
  withDefault,
  QueryParamConfig,
  DelimitedArrayParam,
} from "use-query-params";
import { Row, Col } from "react-bootstrap";
import { FaFilter } from "react-icons/fa";
import moment from "moment";

import AppContext from "../../shared/AppContext";
import { getSeasonFromDate } from "../util/Util";
import { Restrict } from "../components/core/Restrict";
import { Page } from "../components/core/Page";
import { Panel } from "../components/core/Panel";
import { Tabs } from "../components/core/PageTabs";
import { DebugInfo } from "../components/core/DebugInfo";
import { GamePageHeader } from "../components/games/GamePageHeader";
import { GamePageFilter } from "../components/games/GamePageFilter";
import { GameBoxScoreGame } from "../../shared/routers/GameRouter";
import { GameSubPagePostgame } from "../subpages/game/GameSubPagePostgame";
import { GameSubPageShooting } from "../subpages/game/GameSubPageShooting";
import { GameSubPageLineups } from "../subpages/game/GameSubPageLineups";
import { GameSubPageGameFlow } from "../subpages/game/GameSubPageGameFlow";
import { GameSubPageSchedule } from "../subpages/game/GameSubPageSchedule";
import { GameSubPageVideo } from "../subpages/game/GameSubPageVideo";
import { CELTICS_TEAM_ID } from "../constants/AppConstants";
import { trpc } from "../util/tRPC";
import { deepEqual } from "../util/Util";
import { GamePageFilters } from "../../shared/routers/GameRouter";
import { GameSubPageInjuryReport } from "../subpages/game/GameSubPageInjuryReport";

const DEFAULT_FILTERS: GamePageFilters = {
  quarters: ["1", "2", "3", "4", "OT"],
  leverage: "low",
};

export function GamePage() {
  const { id } = useParams();

  // Page state query params.
  const [queryParams, setQueryParams] = useQueryParams({
    tab: withDefault(StringParam, "postgame"),
    scrollTo: StringParam,
  });
  const { tab, scrollTo } = queryParams;

  // Game filter query params.
  const [gameFilters, setGameFilters] = useQueryParams({
    leverage: withDefault(StringParam, "low"),
    quarters: withDefault(DelimitedArrayParam, [
      "1",
      "2",
      "3",
      "4",
      "OT",
    ]) as QueryParamConfig<string[]>,
  });

  useEffect(() => {
    const el = document.querySelector(`#${scrollTo}`);
    if (!el) return;
    const yOffset = -76; // The navbar + team selector.
    const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
  }, [scrollTo]);

  const { data: gameBoxScoreGames } = trpc.game.getGameBoxScoreGames.useQuery({
    gameId: id,
  });
  const home =
    gameBoxScoreGames &&
    gameBoxScoreGames.find((gbsg) => gbsg.homeAway === "home");
  const away =
    gameBoxScoreGames &&
    gameBoxScoreGames.find((gbsg) => gbsg.homeAway === "away");

  const onSubLinkClick = (subLink: string) => {
    const tab = Object.keys(pageTabs.tabs).find((pt) => {
      const tarTab = pageTabs.tabs[pt];
      if (!tarTab) return;
      return tarTab.sublinks.find((sl) => sl.refId === subLink);
    });
    if (!tab) return;
    setQueryParams({ tab, scrollTo: subLink });
  };

  if (!id) return null;

  const filtered = !deepEqual(gameFilters, DEFAULT_FILTERS);

  const pageTabs = getPageTabs(parseInt(id), gameFilters, filtered, home, away);

  if (!home || !away) {
    // If we don't have home/away data but the query game back then the game
    // hasn't been processed so go to the preview page.
    if (gameBoxScoreGames) {
      return <Navigate replace to={`/preview/${id}`} />;
    }
    return null;
  }

  const gameFiltersVisible = filtered;

  const title = `${away.teamabbreviation} @ ${home.teamabbreviation} - ${moment(
    home.gameDateTimeStr
  ).format("MM/DD/YYYY")}`;

  const ptsByQuarter: Record<number, { homePts: number; awayPts: number }> = {};
  const numQuarters = home.numQuarters;
  for (let i = 0; i < numQuarters; i++) {
    ptsByQuarter[i + 1] = {
      homePts: getQuarterPts(i + 1, home),
      awayPts: getQuarterPts(i + 1, away),
    };
  }

  return (
    <Page
      header={{
        component: (
          <GamePageHeader
            gameId={id}
            homeTeamId={home.teamid}
            awayTeamId={away.teamid}
            homeAbbr={home.teamabbreviation}
            awayAbbr={away.teamabbreviation}
            xPPPHome={home.xPPP}
            xPPPAway={away.xPPP}
            xPtsHome={
              home.xPPP === null || home.numPossessions === null
                ? null
                : home.xPPP * home.numPossessions
            }
            xPtsAway={
              away.xPPP === null || away.numPossessions === null
                ? null
                : away.xPPP * away.numPossessions
            }
            xWinPctHome={home.xWinPct}
            xWinPctAway={away.xWinPct}
            xBaselineWinPctHome={home.xWinBaseline}
            gameDateTimeStr={home.gameDateTimeStr}
            arenaname={home.arenaname}
            location={home.location}
            ptsByQuarter={ptsByQuarter}
            lastUpdatedStr={""}
          />
        ),
      }}
      title={title}
      tabs={pageTabs}
      activeTab={tab}
      onTabClick={(newTab) => setQueryParams({ tab: newTab })}
      onSubLinkClick={(subLink) => onSubLinkClick(subLink)}
    >
      <Restrict roles={[]} emails={["chrisbu@celtics.com"]}>
        <Row>
          <Col md={12}>
            {tab !== "schedules" && tab !== "injuryReport" && (
              <Panel
                header={
                  <div>
                    [CHRISBU-ONLY] Game Filters <FaFilter />
                  </div>
                }
                defaultExpanded={gameFiltersVisible}
              >
                <GamePageFilter
                  onChange={(
                    key: string,
                    newVal: string | number | null | string[] | number[]
                  ) => setGameFilters({ [key]: newVal })}
                  onReset={() => setGameFilters(DEFAULT_FILTERS)}
                  filters={gameFilters}
                />
              </Panel>
            )}
          </Col>
        </Row>
      </Restrict>
    </Page>
  );
}

function getPageTabs(
  gameId: number,
  filters: GamePageFilters,
  filtered: boolean,
  home?: GameBoxScoreGame,
  away?: GameBoxScoreGame
): Tabs {
  const homeTeamId = home && home.teamid.toString();
  const homeTeam = home && home.teamabbreviation;
  const awayTeamId = away && away.teamid.toString();
  const awayTeam = away && away.teamabbreviation;

  // Fall back to current season if we fail to figure out the season.
  const season =
    (home && getSeasonFromDate(home.gameDateTimeStr)) ||
    AppContext.currentSeason.toString();

  const leagueFilters = {
    seasonStart: season,
    seasonEnd: season,
    preseason: AppContext.inPreseason ? 1 : 0,
    regularSeason: 1,
    postseason: 1,
  };

  // Post Game
  const { data: homeTeamStats } = trpc.team.getTeamStats.useQuery({
    gameIds: gameId.toString(),
    teamId: homeTeamId,
    quarters: filters.quarters.join(","),
  });
  const { data: gameExpectedStats } = trpc.game.getGameExpectedStats.useQuery({
    gameIds: [gameId],
  });
  const { data: awayTeamStats } = trpc.team.getTeamStats.useQuery({
    gameIds: gameId.toString(),
    teamId: awayTeamId,
    quarters: filters.quarters.join(","),
  });
  const { data: boxscores } = trpc.game.getGameBoxScores.useQuery({
    gameIds: [gameId],
  });
  const { data: leagueStats } = trpc.team.getTeamStats.useQuery(leagueFilters);

  const { data: gameActions } = trpc.team.getTeamActionsBreakdown.useQuery({
    gameIds: gameId.toString(),
  });
  const { data: seasonActions } =
    trpc.team.getTeamActionsBreakdown.useQuery(leagueFilters);

  const { data: teamRebounds } = trpc.game.getGameTeamReboundModel.useQuery({
    gameIds: [gameId],
  });
  const { data: crashAttempts } = trpc.game.getCrashAttempts.useQuery({
    gameIds: [gameId],
  });
  const { data: playerRebounds } = trpc.game.getGamePlayerReboundModel.useQuery(
    {
      gameIds: [gameId],
    }
  );
  const { data: expectedTovs } = trpc.game.getGameExpectedTurnovers.useQuery({
    gameIds: [gameId],
  });
  const { data: attackAvoid } = trpc.game.getGameAttackAvoid.useQuery({
    gameIds: [gameId],
  });
  const { data: chanceStartTypeData } =
    trpc.chance.getChanceEfficiencyPerStartType.useQuery({
      gameIds: [gameId],
    });

  const { data: chanceStartTypeCompData } =
    trpc.chance.geteffPerStartTypeForCompGameByPeriod.useQuery({
      teamId: CELTICS_TEAM_ID,
    });

  // Shooting
  const { data: shots } = trpc.shot.getShots.useQuery({
    filters: {
      gameIds: gameId ? [gameId.toString()] : [],
    },
  });

  // Lineups
  const { data: lineupBreakdowns } = trpc.game.getLineupBreakdowns.useQuery({
    gameIds: [gameId],
  });

  // Game Flow
  const { data: lineups } = trpc.game.getGameLineups.useQuery({
    gameIds: [gameId],
  });
  const { data: players } = trpc.game.getGamePlayers.useQuery({
    gameIds: [gameId],
  });
  const { data: possessions } = trpc.game.getGamePossessions.useQuery({
    gameIds: [gameId],
  });
  const { data: awaySubs } = trpc.team.getTeamSubstitutionPatterns.useQuery({
    teamId: awayTeamId,
    games: gameId.toString(),
  });

  const { data: homeSubs } = trpc.team.getTeamSubstitutionPatterns.useQuery({
    teamId: homeTeamId,
    games: gameId.toString(),
  });

  const { data: gameWinProb } = trpc.game.getGameWinProbability.useQuery({
    gameIds: [gameId],
  });

  // Schedule
  const { data: awaySchedule } = trpc.team.getTeamSchedule.useQuery({
    teamId: awayTeamId,
    seasonStart: season,
    seasonEnd: season,
  });
  const { data: homeSchedule } = trpc.team.getTeamSchedule.useQuery({
    teamId: homeTeamId,
    seasonStart: season,
    seasonEnd: season,
  });

  // Injury Report
  const { data: gameInjuries } = trpc.game.getGameInjuries.useQuery({
    gameId: gameId,
  });

  // Video
  const { data: gameSegments } = trpc.synergy.getSynergyGameSegments.useQuery({
    gameIds: [gameId],
  });
  const { data: gameClips } = trpc.synergy.getSynergyGameClips.useQuery({
    gameIds: [gameId],
  });
  const { data: gameDetails } = trpc.game.getGameDetails.useQuery({
    gameIds: [gameId],
  });

  const pageTabs = {
    tabs: {
      postgame: {
        label: "Overview",
        sublinks: [
          { label: "Team Shot Quality", refId: "teamShotQuality" },
          { label: "Player Shot Quality", refId: "playerShotQuality" },
          { label: "Box Scores", refId: "boxScores" },
          { label: "Offensive and Defensive Breakdown", refId: "breakdown" },
          { label: "Team Stat Comparison", refId: "teamStatComparison" },
          { label: "Team Rebounding", refId: "teamRebounds" },
          { label: "Team Crashing", refId: "teamCrashing" },
          { label: "Player Rebounding", refId: "playerRebounds" },
          {
            label: "Team Shot Quality Breakdown",
            refId: "teamShotQualityBreakdown",
          },
          {
            label: "Possession Start Type Efficiency Breakdown",
            refId: "startTypeEfficiency",
          },
        ],
        content: (
          <div>
            <GameSubPagePostgame
              filtered={filtered}
              home={home}
              away={away}
              fromDate={home ? home.gameDateTimeStr : ""}
              toDate={home ? home.gameDateTimeStr : ""}
              shots={
                shots
                  ? shots.filter((s) => {
                      const period = s.period;
                      if (period !== null && period > 4) {
                        return filters.quarters.includes("OT");
                      } else {
                        return (
                          period !== null &&
                          filters.quarters.includes(period.toString())
                        );
                      }
                    })
                  : undefined
              }
              leagueStats={leagueStats}
              homeTeamStats={homeTeamStats}
              awayTeamStats={awayTeamStats}
              // TODO(chrisbu): Fix everything below this point for quarter and
              // leverage filtering.
              gameExpectedStats={gameExpectedStats}
              boxscores={boxscores}
              gameActions={gameActions}
              seasonActions={seasonActions}
              teamRebounds={teamRebounds}
              crashAttempts={crashAttempts}
              playerRebounds={playerRebounds}
              expectedTovs={expectedTovs}
              attackAvoid={attackAvoid}
              chanceStartTypeData={chanceStartTypeData}
              chanceStartTypeCompData={chanceStartTypeCompData}
            />
            <DebugInfo gameId={gameId} />
          </div>
        ),
      },
      shooting: {
        label: "Shooting",
        content: (
          <div>
            <GameSubPageShooting
              filtered={filtered}
              home={home}
              away={away}
              shots={
                shots
                  ? shots.filter((s) => {
                      const period = s.period;
                      if (period !== null && period > 4) {
                        return filters.quarters.includes("OT");
                      } else {
                        return (
                          period !== null &&
                          filters.quarters.includes(period.toString())
                        );
                      }
                    })
                  : undefined
              }
            />
            <DebugInfo gameId={gameId} />
          </div>
        ),
        sublinks: [
          { label: `Shot Quality · ${awayTeam}`, refId: "shotQualityAway" },
          { label: `Shot Quality · ${homeTeam}`, refId: "shotQualityHome" },
          { label: `Shot Charts · ${awayTeam}`, refId: "shotChartsAway" },
          { label: `Shot Charts · ${homeTeam}`, refId: "shotChartsHome" },
        ],
      },

      lineups: {
        label: "Lineups",
        content: (
          <div>
            <GameSubPageLineups
              filtered={filtered}
              lineupBreakdowns={
                lineupBreakdowns
                  ? lineupBreakdowns.filter((lb) => {
                      const period = lb.period;
                      if (period !== null && period > 4) {
                        return filters.quarters.includes("OT");
                      } else {
                        return (
                          period !== null &&
                          filters.quarters.includes(period.toString())
                        );
                      }
                    })
                  : undefined
              }
              home={home}
              away={away}
            />
            <DebugInfo gameId={gameId} />
          </div>
        ),
        sublinks: [
          { label: "Lineups Summary", refId: "lineupsSummary" },
          { label: "Lineups By Time", refId: "lineupsByTime" },
        ],
      },

      gameFlow: {
        label: "Game Flow",
        content: (
          <div>
            <GameSubPageGameFlow
              filtered={filtered}
              home={home}
              away={away}
              lineups={lineups}
              players={players}
              possessions={
                possessions
                  ? possessions.filter((p) => {
                      const period = p.Period;
                      if (period !== null && period > 4) {
                        return filters.quarters.includes("OT");
                      } else {
                        return (
                          period !== null &&
                          filters.quarters.includes(period.toString())
                        );
                      }
                    })
                  : undefined
              }
              awaySubs={
                awaySubs
                  ? awaySubs.filter((as) => {
                      const period = as.period;
                      if (period !== null && period > 4) {
                        return filters.quarters.includes("OT");
                      } else {
                        return (
                          period !== null &&
                          filters.quarters.includes(period.toString())
                        );
                      }
                    })
                  : undefined
              }
              homeSubs={
                homeSubs
                  ? homeSubs.filter((hs) => {
                      const period = hs.period;
                      if (period !== null && period > 4) {
                        return filters.quarters.includes("OT");
                      } else {
                        return (
                          period !== null &&
                          filters.quarters.includes(period.toString())
                        );
                      }
                    })
                  : undefined
              }
              gameWinProb={
                gameWinProb
                  ? gameWinProb.filter((gw) => {
                      const period = gw.period;
                      if (period !== null && period > 4) {
                        return filters.quarters.includes("OT");
                      } else {
                        return (
                          period !== null &&
                          filters.quarters.includes(period.toString())
                        );
                      }
                    })
                  : undefined
              }
            />
            <DebugInfo gameId={gameId} />
          </div>
        ),
        sublinks: [
          { label: "Game Flow", refId: "gameFlow" },
          { label: "Sub Pattern", refId: "subPattern" },
          // TODO(chrisbu): Uncomment when/if this is launched.
          // { label: "Win Probability", refId: "winProbability" },
        ],
      },

      schedules: {
        label: "Schedules",
        content: (
          <div>
            <GameSubPageSchedule
              gameId={gameId}
              home={home}
              away={away}
              awaySchedule={awaySchedule}
              homeSchedule={homeSchedule}
            />
            <DebugInfo gameId={gameId} />
          </div>
        ),
        sublinks: [
          { label: "Away Schedule", refId: "awaySchedule" },
          { label: "Home Schedule", refId: "homeSchedule" },
        ],
      },

      injuryReport: {
        label: "Injury Report",
        content: (
          <div>
            <GameSubPageInjuryReport
              game={gameDetails}
              injuries={gameInjuries}
            />
            <DebugInfo gameId={gameId} />
          </div>
        ),
        sublinks: [{ label: "Injury Report", refId: "injuryReport" }],
      },

      video: {
        label: "Video",
        content: (
          <div>
            <GameSubPageVideo
              filtered={filtered}
              home={home}
              away={away}
              gameSegments={
                gameSegments
                  ? gameSegments.filter((gs) => {
                      const period = gs.gameQuarter;
                      if (period !== null && period > 4) {
                        return filters.quarters.includes("OT");
                      } else {
                        return (
                          period !== null &&
                          filters.quarters.includes(period.toString())
                        );
                      }
                    })
                  : undefined
              }
              gameClips={
                gameClips
                  ? gameClips.filter((gc) => {
                      const period = gc.gameQuarter;
                      if (period !== null && period > 4) {
                        return filters.quarters.includes("OT");
                      } else {
                        return (
                          period !== null &&
                          filters.quarters.includes(period.toString())
                        );
                      }
                    })
                  : undefined
              }
              gameDetails={gameDetails}
            />
            <DebugInfo gameId={gameId} />
          </div>
        ),
        sublinks: [{ label: "Video", refId: "video" }],
      },
    },
  };

  return pageTabs;
}

function getQuarterPts(quarter: number, gbsg: GameBoxScoreGame) {
  if (quarter === 1) return gbsg.q1pts;
  else if (quarter === 2) return gbsg.q2pts;
  else if (quarter === 3) return gbsg.q3pts;
  else if (quarter === 4) return gbsg.q4pts;
  else if (quarter === 5) return gbsg.q5pts;
  else if (quarter === 6) return gbsg.q6pts;
  else if (quarter === 7) return gbsg.q7pts;
  else if (quarter === 8) return gbsg.q8pts;
  return 0;
}
