import React, { useState, useMemo } from "react";

import { Table, SortingState, createColumnHelper } from "../core/Table";
import { PlayerTableCell } from "../core/TableCell";
import { PlayerMeasurementsBoxPlots } from "../player/PlayerMeasurementsBoxPlots";
import {
  PlayerBio,
  PlayerModeledMeasurements,
} from "../../../shared/routers/PlayerRouter";
import {
  decFormat,
  decFormat2,
  footFormat,
  makePlusMinus,
  poundsFormat,
  intFormat,
} from "../../util/Format";

const measurementsColumnHelper =
  createColumnHelper<PlayerModeledMeasurements>();

export function PlayerCompareMeasurementsTable(props: {
  modeledMeasurements: PlayerModeledMeasurements[];
  bios: PlayerBio[];
  showBoxPlots: boolean;
}) {
  const { modeledMeasurements, bios, showBoxPlots } = props;
  const [sorting, setSorting] = useState<SortingState>();

  const columns = useMemo(() => {
    let g = 0;

    if (!bios) return [];

    return [
      measurementsColumnHelper.accessor("playerId", {
        header: () => "Player",
        cell: (info) => (
          <PlayerTableCell
            id={info.getValue()}
            name={bios.find((b) => b.playerId === info.getValue())?.fullName}
          />
        ),
        meta: { group: g++ },
      }),
      measurementsColumnHelper.accessor("weight", {
        header: () => "Weight",
        cell: (info) => poundsFormat(info.getValue()),
        meta: { group: g },
      }),
      measurementsColumnHelper.accessor("bodyFat", {
        header: () => "BF%",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g++ },
      }),
      measurementsColumnHelper.accessor("heightNoShoes", {
        header: () => "Ht No Shoes",
        cell: (info) => footFormat(info.getValue()),
        meta: { group: g },
      }),
      measurementsColumnHelper.accessor("heightWithShoes", {
        header: () => "Ht Shoes",
        cell: (info) => footFormat(info.getValue()),
        meta: { group: g++ },
      }),
      measurementsColumnHelper.accessor("wingspan", {
        header: () => "Wingspan",
        cell: (info) => footFormat(info.getValue()),
        meta: { group: g },
      }),
      measurementsColumnHelper.accessor(
        (row) =>
          row.wingspan && row.heightNoShoes && row.wingspan - row.heightNoShoes,
        {
          id: "deltaWingspan",
          header: () => "∆ Wingspan",
          cell: (info) => makePlusMinus(decFormat)(info.getValue()),
          meta: { group: g },
        }
      ),
      measurementsColumnHelper.accessor("standReach", {
        header: () => "Reach",
        cell: (info) => footFormat(info.getValue()),
        meta: { group: g++ },
      }),
      measurementsColumnHelper.accessor("verticalJumpNoStep", {
        header: () => "Vert No Step",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g },
      }),
      measurementsColumnHelper.accessor("verticalJumpOneStep", {
        header: () => "Vert Max",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g++ },
      }),
      measurementsColumnHelper.accessor("handLength", {
        header: () => "Hand L",
        cell: (info) => decFormat2(info.getValue()),
        meta: { group: g },
      }),
      measurementsColumnHelper.accessor("handWidth", {
        header: () => "Hand W",
        cell: (info) => decFormat2(info.getValue()),
        meta: { group: g++ },
      }),
      measurementsColumnHelper.accessor("benchPress", {
        header: () => "Bench",
        cell: (info) => intFormat(info.getValue()),
        meta: { group: g++ },
      }),
      measurementsColumnHelper.accessor("laneAgility", {
        header: () => "Lane Agi",
        cell: (info) => decFormat2(info.getValue()),
        meta: { group: g },
      }),
      measurementsColumnHelper.accessor("run34", {
        header: () => "3/4",
        cell: (info) => decFormat2(info.getValue()),
        meta: { group: g++ },
      }),
    ];
  }, [bios]);

  return (
    <>
      <Table
        data={modeledMeasurements}
        columns={columns}
        sorting={sorting}
        setSorting={setSorting}
        autoWidth={true}
        showColorOnHover={true}
        showRowIndex={false}
      />
      {showBoxPlots && (
        <PlayerMeasurementsBoxPlots
          names={bios.map((b) => {
            return { name: b.fullName, id: b.playerId };
          })}
          measurements={modeledMeasurements}
        />
      )}
    </>
  );
}
