import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Alert, Button, Col, Form, Row, ListGroup } from "react-bootstrap";
import { FaPlayCircle } from "react-icons/fa";
import {
  useQueryParams,
  StringParam,
  DelimitedArrayParam,
  QueryParamConfig,
  withDefault,
} from "use-query-params";
import moment from "moment";
import { createStyles, makeStyles } from "@material-ui/styles";

import { Page } from "../components/core/Page";
import { Panel } from "../components/core/Panel";
import { MultiSelect } from "../components/core/MultiSelect";
import { Table, createColumnHelper } from "../components/core/Table";
import { VideoModal } from "../components/video/VideoModal";
import { Spinner } from "../components/core/Spinner";
import {
  SynergyClip,
  SynergyLeague,
  SynergyTeam,
} from "../../shared/routers/SynergyRouter";
import {
  dateFormat,
  gameClockFormat,
  collegeGameClockFormat,
  intlGameClockFormat,
  period,
} from "../util/Format";
import { groupBy, shuffle } from "../../shared/util/Collections";
import AppContext from "../../shared/AppContext";
import { trpc } from "../util/tRPC";

const CLIP_LIMIT = 10_000;

const useStyles = makeStyles(() =>
  createStyles({
    clipSelector: {
      "& .active a": {
        color: "white",
      },
      "& .list-group-item.active": {
        borderColor: "white",
      },
    },
    topLevelCategory: {
      textAlign: "center",
    },
    subCategory: {
      textAlign: "right",
    },
    datePicker: {
      width: "auto",
      display: "inline-block",
    },
    group: {
      margin: 4,
      padding: 12,
      borderRadius: 4,
      "& .row": {
        marginBottom: 12,
      },
    },
    playAll: {
      width: "100%",
    },
    largeCheck: {
      textAlign: "center",
      fontSize: 16,
    },
  })
);

export function VideoPage() {
  const { data: allLeagues } = trpc.synergy.getSynergyLeagues.useQuery();

  const { data: allTeams } = trpc.synergy.getSynergyTeams.useQuery();

  if (!allLeagues || !allTeams) return null;

  return <VideoPageInner allLeagues={allLeagues} allTeams={allTeams} />;
}

function VideoPageInner(props: {
  allLeagues: SynergyLeague[];
  allTeams: SynergyTeam[];
}) {
  const classes = useStyles();
  const [queryParams, setQueryParams] = useQueryParams({
    players: DelimitedArrayParam as QueryParamConfig<string[]>,
    teams: DelimitedArrayParam as QueryParamConfig<string[]>,
    leagues: DelimitedArrayParam as QueryParamConfig<string[]>,
    dateFrom: StringParam as QueryParamConfig<string>,
    dateTo: StringParam as QueryParamConfig<string>,
    seasons: DelimitedArrayParam as QueryParamConfig<string[]>,
    leverage: withDefault(StringParam, "none"),
  });
  const { players, teams, leagues, dateFrom, dateTo, seasons, leverage } =
    queryParams;

  const { allLeagues, allTeams } = props;

  const [selectedGames, setSelectedGames] = useState<Set<string>>(new Set());

  const [isFirstTime, setIsFirstTime] = useState(true);

  // If it is the first time and fetch the first player (via their id).
  const [playerSearchQuery, setPlayerSearchQuery] = useState(
    players && players.length ? players[0] : ""
  );

  const { data: autoCompletePlayers } =
    trpc.search.getVideoSearchResults.useQuery({
      searchQuery: playerSearchQuery || "",
    });

  if (isFirstTime) {
    setIsFirstTime(false);
  }

  const { data: clips, isFetching: loading } =
    trpc.synergy.getSynergyClips.useQuery(
      {
        limit: CLIP_LIMIT.toString(),
        players: players && players.join(","),
        teams: teams && teams.join(","),
        leagues: leagues && leagues.map((l) => `'${l}'`).join(","),
        dateFrom,
        dateTo,
        seasons: seasons && seasons.join(","),
        leverage: leverage,
      },
      {
        onSettled: (res) =>
          setSelectedGames(new Set((res || []).map((clip) => clip.iGameId))),
      }
    );

  const videoTitle = [
    (autoCompletePlayers || [])
      .filter((p) => players?.includes(p.playerId.toString()))
      .map((p) => p.playerName),
    (allTeams || [])
      .filter((t) => teams?.includes(t.id))
      .map((t) => t.teamName),
  ]
    .flat()
    .join(", ");

  const seasonsClipsAvail = [];
  for (
    let s = AppContext.oldestClipsSeason;
    s <= Number(AppContext.currentSeason);
    s++
  ) {
    seasonsClipsAvail.push({
      value: s.toString(),
      label: (s - 1).toString() + "-" + s.toString().slice(-2),
    });
  }

  return (
    <Page header={{ text: "Video" }} title="Video">
      <Row>
        <Col md={12}>
          <Panel header="Filters">
            <div>
              <Row>
                <Col className={classes.group}>
                  <Row>
                    <Col>
                      <Form.Label>Player</Form.Label>
                      <MultiSelect
                        values={(autoCompletePlayers || []).map((p) => {
                          return {
                            value: p.playerId.toString(),
                            label: p.playerName,
                            aux1: [p.college, p.birthYear]
                              .filter((a) => !!a)
                              .join(", "),
                            aux2: "",
                          };
                        })}
                        selected={players}
                        onTextChange={(text) => setPlayerSearchQuery(text)}
                        onChange={(vals) => {
                          if (vals.length === 0) {
                            // When we remove the selection also clear the search query.
                            setPlayerSearchQuery("");
                          }
                          setQueryParams({ players: vals });
                        }}
                        limit={1}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Team</Form.Label>
                      <MultiSelect
                        values={allTeams.map((t) => {
                          return {
                            value: t.id,
                            label: t.teamName,
                            aux1: t.league,
                          };
                        })}
                        selected={teams}
                        onChange={(vals) => setQueryParams({ teams: vals })}
                        limit={1}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Leagues</Form.Label>
                      <MultiSelect
                        values={allLeagues.map((l) => {
                          return {
                            value: l.leagueKey,
                            label: l.league,
                          };
                        })}
                        selected={leagues}
                        onChange={(vals) => setQueryParams({ leagues: vals })}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col className={classes.group}>
                  <Row>
                    <Col>
                      <Form.Label>Seasons</Form.Label>
                      <MultiSelect
                        values={seasonsClipsAvail}
                        selected={seasons}
                        onChange={(vals) => setQueryParams({ seasons: vals })}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Date</Form.Label>
                      <div>
                        <Form.Control
                          type="date"
                          className={classes.datePicker}
                          value={dateFrom}
                          onChange={(evt) =>
                            setQueryParams({ dateFrom: evt.target.value })
                          }
                        ></Form.Control>
                        {" to "}
                        <Form.Control
                          type="date"
                          className={classes.datePicker}
                          value={dateTo}
                          onChange={(evt) =>
                            setQueryParams({ dateTo: evt.target.value })
                          }
                        ></Form.Control>
                      </div>
                    </Col>
                    <Col>
                      <Form.Label>Leverage (NBA Only)</Form.Label>
                      <div>
                        <Form.Select
                          value={leverage}
                          onChange={(
                            evt: React.ChangeEvent<HTMLSelectElement>
                          ) => {
                            setQueryParams({ leverage: evt.target.value });
                          }}
                        >
                          {[
                            { value: "none", label: "All Possessions" },
                            { value: "low", label: "No Garbage Time" },
                            { value: "med", label: "Close Game" },
                            { value: "high", label: "High Leverage" },
                          ].map((o) => (
                            <option key={o.value} value={o.value}>
                              {o.label}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {CLIP_LIMIT === (clips && clips.length) && (
                <Row>
                  <Col>
                    <Alert variant="warning">
                      {`Your query matched too many video clips. To improve performance we are only showing some of the below. Please set additional filters if you are looking for specific clips.`}
                    </Alert>
                  </Col>
                </Row>
              )}
            </div>
          </Panel>
        </Col>
        <Col md={6}>
          <Panel header="Video">
            <div>
              {loading && <Spinner />}
              {!loading && clips && clips.length > 0 && (
                <ClipsTable
                  clips={clips}
                  selectedGames={selectedGames}
                  title={videoTitle}
                />
              )}
            </div>
          </Panel>
        </Col>
        <Col md={6}>
          <Panel header="Games">
            <div>
              {loading && <Spinner />}
              {!loading && clips && clips.length > 0 && (
                <GamePanel
                  clips={clips}
                  selectedGames={selectedGames}
                  selectedGamesChange={setSelectedGames}
                />
              )}
            </div>
          </Panel>
        </Col>
      </Row>
    </Page>
  );
}

interface ClipCategory {
  title: string;
  action?: boolean;
  parent?: string;
  filterFunc: (clip: SynergyClip) => boolean;
}

const offClipCategories: ClipCategory[] = [
  {
    title: "All Offense",
    filterFunc: (c: SynergyClip) => !!c.is_offense,
  },
  {
    title: "Transition",
    parent: "All Offense",
    filterFunc: (c: SynergyClip) => !!(c.is_offense && c.is_transition),
  },
  {
    title: "Spot Up",
    parent: "All Offense",
    filterFunc: (c: SynergyClip) => !!(c.is_offense && c.is_spot_up),
  },
  {
    title: "Pnr Bhr",
    parent: "All Offense",
    filterFunc: (c: SynergyClip) => !!(c.is_offense && c.is_pnr_bhr),
  },
  {
    title: "Hand Off",
    parent: "All Offense",
    filterFunc: (c: SynergyClip) => !!(c.is_offense && c.is_hand_off),
  },
  {
    title: "Off Screen",
    parent: "All Offense",
    filterFunc: (c: SynergyClip) => !!(c.is_offense && c.is_off_screen),
  },
  {
    title: "Iso",
    parent: "All Offense",
    filterFunc: (c: SynergyClip) => !!(c.is_offense && c.is_iso),
  },
  {
    title: "Cut",
    parent: "All Offense",
    filterFunc: (c: SynergyClip) => !!(c.is_offense && c.is_cut),
  },
  {
    title: "Put Back",
    parent: "All Offense",
    filterFunc: (c: SynergyClip) => !!(c.is_offense && c.is_put_back),
  },
  {
    title: "Post Up",
    parent: "All Offense",
    filterFunc: (c: SynergyClip) => !!(c.is_offense && c.is_post_up),
  },
  {
    title: "Pnr Screener",
    parent: "All Offense",
    filterFunc: (c: SynergyClip) => !!(c.is_offense && c.is_pnr_screener),
  },
  {
    title: "Foul",
    parent: "All Offense",
    filterFunc: (c: SynergyClip) => !!(c.is_offense && c.is_foul),
  },
  {
    title: "No Play Type",
    parent: "All Offense",
    filterFunc: (c: SynergyClip) => !!(c.is_offense && c.is_no_play_type),
  },
  {
    action: true,
    title: "Passes",
    filterFunc: (c: SynergyClip) => !!(c.is_pass && c.is_offense),
  },
  {
    action: true,
    title: "Turnovers",
    filterFunc: (c: SynergyClip) => !!(c.is_turnover && c.is_offense),
  },
  {
    action: true,
    title: "Shots",
    filterFunc: (c: SynergyClip) => !!c.is_shooter,
  },
  {
    action: true,
    title: "Dunk",
    parent: "Shots",
    filterFunc: (c: SynergyClip) => !!(c.shot_type === "Dunk" && c.is_shooter),
  },
  {
    action: true,
    title: "Floater/Runner",
    parent: "Shots",
    filterFunc: (c: SynergyClip) =>
      !!(c.shot_type === "Floater/Runner" && c.is_shooter),
  },
  {
    action: true,
    title: "Hook Shot",
    parent: "Shots",
    filterFunc: (c: SynergyClip) =>
      !!(c.shot_type === "Hook Shot" && c.is_shooter),
  },
  {
    action: true,
    title: "Jumper",
    parent: "Shots",
    filterFunc: (c: SynergyClip) =>
      !!(c.shot_type === "Jumper" && c.is_shooter),
  },
  {
    action: true,
    title: "Layup",
    parent: "Shots",
    filterFunc: (c: SynergyClip) => !!(c.shot_type === "Layup" && c.is_shooter),
  },
  {
    action: true,
    title: "Tip Shot",
    parent: "Shots",
    filterFunc: (c: SynergyClip) =>
      !!(c.shot_type === "Tip Shot" && c.is_shooter),
  },
  {
    action: true,
    title: "Offensive Rebounds",
    filterFunc: (c: SynergyClip) => !!(c.is_orb && c.is_offense),
  },
  {
    action: true,
    title: "Free Throws",
    filterFunc: (c: SynergyClip) => !!(c.is_free_throw && c.is_offense),
  },
];

const defClipCategories: ClipCategory[] = [
  {
    title: "All Defense",
    filterFunc: (c: SynergyClip) => !!c.is_defense,
  },
  {
    title: "Transition Def.",
    parent: "All Defense",
    filterFunc: (c: SynergyClip) => !!(c.is_defense && c.is_transition),
  },
  {
    title: "Spot Up Def.",
    parent: "All Defense",
    filterFunc: (c: SynergyClip) => !!(c.is_defense && c.is_spot_up),
  },
  {
    title: "Pnr Bhr Def.",
    parent: "All Defense",
    filterFunc: (c: SynergyClip) => !!(c.is_defense && c.is_pnr_bhr),
  },
  {
    title: "Hand Off Def.",
    parent: "All Defense",
    filterFunc: (c: SynergyClip) => !!(c.is_defense && c.is_hand_off),
  },
  {
    title: "Off Screen Def.",
    parent: "All Defense",
    filterFunc: (c: SynergyClip) => !!(c.is_defense && c.is_off_screen),
  },
  {
    title: "Iso Def.",
    parent: "All Defense",
    filterFunc: (c: SynergyClip) => !!(c.is_defense && c.is_iso),
  },
  {
    title: "Cut Def.",
    parent: "All Defense",
    filterFunc: (c: SynergyClip) => !!(c.is_defense && c.is_cut),
  },
  {
    title: "Put Back Def.",
    parent: "All Defense",
    filterFunc: (c: SynergyClip) => !!(c.is_defense && c.is_put_back),
  },
  {
    title: "Post Up Def.",
    parent: "All Defense",
    filterFunc: (c: SynergyClip) => !!(c.is_defense && c.is_post_up),
  },
  {
    title: "Pnr Screener Def.",
    parent: "All Defense",
    filterFunc: (c: SynergyClip) => !!(c.is_defense && c.is_pnr_screener),
  },
  {
    title: "Foul Def.",
    parent: "All Defense",
    filterFunc: (c: SynergyClip) => !!(c.is_defense && c.is_foul),
  },
  {
    title: "No Play Type Def.",
    parent: "All Defense",
    filterFunc: (c: SynergyClip) => !!(c.is_defense && c.is_no_play_type),
  },
  {
    action: true,
    title: "Steals",
    filterFunc: (c: SynergyClip) => !!(c.is_steal && c.is_defense),
  },
  {
    action: true,
    title: "Blocks",
    filterFunc: (c: SynergyClip) => !!(c.is_block && c.is_defense),
  },
  {
    action: true,
    title: "Shots Defended",
    filterFunc: (c: SynergyClip) => !!(c.is_defend_shooter && c.is_defense),
  },
  {
    action: true,
    title: "Dunk Def.",
    parent: "Shots Defended",
    filterFunc: (c: SynergyClip) =>
      !!(c.shot_type === "Dunk" && c.is_defend_shooter),
  },
  {
    action: true,
    title: "Floater/Runner Def.",
    parent: "Shots Defended",
    filterFunc: (c: SynergyClip) =>
      !!(c.shot_type === "Floater/Runner" && c.is_defend_shooter),
  },
  {
    action: true,
    title: "Hook Shot Def.",
    parent: "Shots Defended",
    filterFunc: (c: SynergyClip) =>
      !!(c.shot_type === "Hook Shot" && c.is_defend_shooter),
  },
  {
    action: true,
    title: "Jumper Def.",
    parent: "Shots Defended",
    filterFunc: (c: SynergyClip) =>
      !!(c.shot_type === "Jumper" && c.is_defend_shooter),
  },
  {
    action: true,
    title: "Layup Def.",
    parent: "Shots Defended",
    filterFunc: (c: SynergyClip) =>
      !!(c.shot_type === "Layup" && c.is_defend_shooter),
  },
  {
    action: true,
    title: "Tip Shot Def.",
    parent: "Shots Defended",
    filterFunc: (c: SynergyClip) =>
      !!(c.shot_type === "Tip Shot" && c.is_defend_shooter),
  },
  {
    action: true,
    title: "Defensive Rebounds",
    filterFunc: (c: SynergyClip) => !!(c.is_drb && c.is_defense),
  },
];

function ClipsTable(props: {
  clips: SynergyClip[];
  selectedGames: Set<string>;
  title: string;
}) {
  const classes = useStyles();
  const { title } = props;
  const [active, setActive] = useState<Set<string>>(
    new Set(
      offClipCategories
        .filter(
          (cc) => cc.title === "All Offense" || cc.parent === "All Offense"
        )
        .map((cc) => cc.title)
    )
  );
  const [show, setShow] = useState(false);
  const [order, setOrder] = useState<"default" | "randomClip" | "randomGame">(
    "default"
  );
  const [zone, setZone] = useState(true);
  const [man, setMan] = useState(true);

  const [selectedClips, setSelectedClips] =
    useState<
      { label: string; url: string; auxData: Record<string, string> }[]
    >();

  const clips = sortClips(
    props.clips
      .filter((c) => props.selectedGames.has(c.iGameId))
      .filter((c) => (zone && c.is_zone) || (man && !c.is_zone)),
    order
  );

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    setShow(true);
  }, [selectedClips]);

  const toggle = (clipCategory: ClipCategory) => {
    const isSelected = active.has(clipCategory.title);

    if (!isSelected && !clipCategory.parent) {
      // If selecting a parent, select it and all its children.
      active.add(clipCategory.title);
      offClipCategories
        .concat(defClipCategories)
        .filter((cc) => cc.parent === clipCategory.title)
        .forEach((v) => active.add(v.title));
    } else if (isSelected && !clipCategory.parent) {
      // If unselected a parent, unselect it and all its children.
      active.delete(clipCategory.title);
      offClipCategories
        .concat(defClipCategories)
        .filter((cc) => cc.parent === clipCategory.title)
        .forEach((v) => active.delete(v.title));
    } else if (!isSelected && clipCategory.parent) {
      // If selecting a non-parent, select it.
      active.add(clipCategory.title);
    } else if (isSelected && clipCategory.parent) {
      // If the parent is selected it means we actually want to make this the
      // the *only* selection.
      if (active.has(clipCategory.parent)) {
        active.delete(clipCategory.parent);
        offClipCategories
          .concat(defClipCategories)
          .filter(
            (cc) =>
              cc.parent === clipCategory.parent &&
              cc.title !== clipCategory.title
          )
          .forEach((v) => active.delete(v.title));
      } else {
        // If unselecting a non-parent, unselect it.
        active.delete(clipCategory.title);
      }
    }

    setActive(new Set(active));
  };

  const allSelectedClips = clips.filter((clip) => {
    const clipCategories = clip.is_offense
      ? offClipCategories
      : defClipCategories;

    if (clipCategories.filter((cc) => active.has(cc.title)).length === 0) {
      return false;
    }

    const activeActionFilters = clipCategories
      .filter((cc) => active.has(cc.title) && cc.action)
      .map((cc) => cc.filterFunc);

    const activePlayTypeFilters = clipCategories
      .filter((cc) => active.has(cc.title) && !cc.action)
      .map((cc) => cc.filterFunc);

    const matchesActionFilter =
      activeActionFilters.length === 0 ||
      activeActionFilters.some((ff) => ff(clip));

    const matchesPlayTypeFilter =
      activePlayTypeFilters.length === 0 ||
      activePlayTypeFilters.some((ff) => ff(clip));

    return matchesActionFilter && matchesPlayTypeFilter;
  });

  return (
    <div className={classes.clipSelector}>
      <p>
        <Button
          className={classes.playAll}
          disabled={allSelectedClips.length === 0}
          onClick={() => {
            setSelectedClips(
              allSelectedClips.map((clip) => {
                let gameClockStr;
                if (clip.iLeagueId === "5") {
                  gameClockStr = collegeGameClockFormat(clip.iGameClock_start);
                } else if (["1", "8", "10"].includes(clip.iLeagueId)) {
                  gameClockStr = gameClockFormat(clip.iGameClock_start / 10);
                } else {
                  gameClockStr = intlGameClockFormat(clip.iGameClock_start);
                }
                return {
                  label: labelForClip(clip),
                  url: clip.url,
                  auxData: {
                    makeMiss: makeOrMissFromEvent(clip),
                    gameClockStr: gameClockStr || "",
                    game: `${clip.hometeam || "Unknown"} vs. ${
                      clip.visteam || "Unknown"
                    }`,
                    dateStr: moment(clip.dtGameDate, "YYYYMMDD").format("L"),
                    period: period(clip.iGameQuarter),
                  },
                };
              })
            );
          }}
        >
          {`Play Selected (${allSelectedClips.length.toLocaleString()}) `}
          <FaPlayCircle />
        </Button>
      </p>
      <Row>
        {[offClipCategories, defClipCategories].map((clipCategories, i) => (
          <Col key={i}>
            <ListGroup>
              {clipCategories.map((cc) => {
                const categoryClips = clips.filter(cc.filterFunc);
                return (
                  <ListGroup.Item
                    key={cc.title}
                    action
                    onClick={() => toggle(cc)}
                    active={active.has(cc.title)}
                    className={
                      cc.parent ? classes.subCategory : classes.topLevelCategory
                    }
                  >
                    {categoryClips.length ? (
                      <a
                        href="#"
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedClips(
                            categoryClips.map((clip) => {
                              let gameClockStr;
                              if (clip.iLeagueId === "5") {
                                gameClockStr = collegeGameClockFormat(
                                  clip.iGameClock_start
                                );
                              } else if (
                                ["1", "8", "10"].includes(clip.iLeagueId)
                              ) {
                                gameClockStr = gameClockFormat(
                                  clip.iGameClock_start / 10
                                );
                              } else {
                                gameClockStr = intlGameClockFormat(
                                  clip.iGameClock_start
                                );
                              }
                              return {
                                label: labelForClip(clip),
                                url: clip.url,
                                auxData: {
                                  makeMiss: makeOrMissFromEvent(clip),
                                  gameClockStr: gameClockStr || "",
                                  game: `${clip.hometeam} vs. ${clip.visteam}`,
                                  dateStr: moment(
                                    clip.dtGameDate,
                                    "YYYYMMDD"
                                  ).format("L"),
                                  period: period(clip.iGameQuarter),
                                },
                              };
                            })
                          );
                        }}
                      >
                        {`${
                          cc.title
                        } (${categoryClips.length.toLocaleString()}) `}
                        <FaPlayCircle />
                      </a>
                    ) : (
                      <span>
                        {`${
                          cc.title
                        } (${categoryClips.length.toLocaleString()})`}
                      </span>
                    )}
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </Col>
        ))}
      </Row>
      <Form>
        <Form.Check
          type="radio"
          label="Default Order"
          inline
          onChange={() => setOrder("default")}
          checked={order === "default"}
        />
        <Form.Check
          type="radio"
          label="Random Clip"
          inline
          onChange={() => setOrder("randomClip")}
          checked={order === "randomClip"}
        />
        <Form.Check
          type="radio"
          label="Random Game"
          inline
          onChange={() => setOrder("randomGame")}
          checked={order === "randomGame"}
        />
      </Form>
      <Form>
        <Form.Check
          type="checkbox"
          label="Zone"
          inline
          checked={zone}
          onChange={() => setZone(!zone)}
        />
        <Form.Check
          type="checkbox"
          label="Man"
          inline
          checked={man}
          onChange={() => setMan(!man)}
        />
      </Form>
      {selectedClips && (
        <VideoModal
          clips={selectedClips}
          title={title}
          show={show}
          handleClose={handleClose}
          upDownClipSkip={true}
          showSynergyEditor={true}
        />
      )}
    </div>
  );
}

const gameColumnHelper = createColumnHelper<{
  id: string;
  awayTeam: string;
  homeTeam: string;
  date: string;
}>();

function GamePanel(props: {
  clips: SynergyClip[];
  selectedGames: Set<string>;
  selectedGamesChange: (selectedGames: Set<string>) => void;
}) {
  const classes = useStyles();
  const { clips, selectedGames, selectedGamesChange } = props;

  const games = useMemo(() => {
    const ret: Record<
      string,
      { id: string; awayTeam: string; homeTeam: string; date: string }
    > = {};
    for (const clip of clips) {
      if (!ret[clip.iGameId]) {
        ret[clip.iGameId] = {
          id: clip.iGameId,
          awayTeam: clip.visteam || "Unknown",
          homeTeam: clip.hometeam || "Unknown",
          date: clip.dtGameDate.split("T")[0] || "",
        };
      }
    }
    return ret;
  }, [clips]);

  const handleRowClick = useCallback(
    (game?: {
      id: string;
      awayTeam: string;
      homeTeam: string;
      date: string;
    }) => {
      // If we clicked the select all/none checkbox at the top.
      if (!game) {
        const allSelected = Object.keys(games).length === selectedGames.size;
        selectedGamesChange(
          allSelected ? new Set() : new Set([...Object.keys(games)])
        );
        return;
      }

      const isChecked = selectedGames.has(game.id);
      const copySet = new Set([...selectedGames]);
      if (isChecked) {
        copySet.delete(game.id);
      } else {
        copySet.add(game.id);
      }
      selectedGamesChange(copySet);
    },
    [games, selectedGames, selectedGamesChange]
  );

  const columns = useMemo(
    () => [
      gameColumnHelper.display({
        id: "check",
        header: () => (
          <Form.Check
            className={classes.largeCheck}
            type="checkbox"
            checked={Object.keys(games).length === selectedGames.size}
            onChange={() => handleRowClick()}
          />
        ),
        cell: (info) => (
          <Form.Check
            className={classes.largeCheck}
            type="checkbox"
            checked={selectedGames.has(info.row.original.id)}
            onChange={() => handleRowClick(info.row.original)}
          />
        ),
      }),
      gameColumnHelper.accessor("date", {
        header: () => "Date",
        cell: (info) => moment(info.getValue()).format("MM-DD-YYYY"),
      }),
      gameColumnHelper.accessor((row) => `${row.awayTeam} @ ${row.homeTeam}`, {
        id: "game",
        header: () => "Game",
        cell: (info) => info.renderValue(),
      }),
    ],
    [classes.largeCheck, games, handleRowClick, selectedGames]
  );

  return (
    <div>
      <Table
        data={Object.values(games).sort((a, b) => (a.date > b.date ? -1 : 1))}
        columns={columns}
        showRowIndex={false}
      ></Table>
    </div>
  );
}

function labelForClip(clip: SynergyClip) {
  return `${period(clip.iGameQuarter)} ${gameClockFormat(
    clip.iGameClock_start
  )} ${dateFormat(moment(clip.dtGameDate).toDate())} ${clip.string_event}`;
}

function sortClips(
  clips: SynergyClip[],
  order: "default" | "randomClip" | "randomGame"
) {
  if (order === "default") {
    // Sort clips in descending order of game date but for each game make sure
    // they are sorted sequentially (i.e clips should start at start of game).
    return clips.sort((a, b) => {
      if (a.dtGameDate !== b.dtGameDate) {
        return a.dtGameDate > b.dtGameDate ? -1 : 1;
      } else if (a.iGameQuarter !== b.iGameQuarter) {
        if (a.iGameQuarter === null) return -1;
        else if (b.iGameQuarter === null) return 1;
        return a.iGameQuarter > b.iGameQuarter ? 1 : -1;
      }
      return a.iGameClock_start > b.iGameClock_start ? -1 : 1;
    });
  } else if (order === "randomGame") {
    // Game clips are kept together (and in sequential game order) but the order
    // of these grouped clips is shuffled.
    const groupedByGame = groupBy(clips, (c: SynergyClip) => c.iGameId);
    const shuffledIds = shuffle(Object.keys(groupedByGame));
    return shuffledIds
      .map((gameId: string) => {
        return (groupedByGame[gameId] || []).sort((a, b) => {
          if (a.iGameQuarter !== b.iGameQuarter) {
            if (a.iGameQuarter === null) return -1;
            else if (b.iGameQuarter === null) return 1;
            return a.iGameQuarter > b.iGameQuarter ? 1 : -1;
          }
          return a.iGameClock_start > b.iGameClock_start ? -1 : 1;
        });
      })
      .flat();
  }
  // Just shuffle all the clips.
  return shuffle(clips);
}

function makeOrMissFromEvent(clip: SynergyClip) {
  const event = clip.string_event.toLocaleLowerCase();
  if (event.includes("miss")) {
    return "Miss";
  } else if (event.includes("make")) {
    return "Make";
  }
  return "";
}
