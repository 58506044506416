import React, { useState } from "react";
import {
  useQueryParams,
  withDefault,
  JsonParam,
  QueryParamConfig,
} from "use-query-params";
import { Button, Col, Form, Row } from "react-bootstrap";

import { Page } from "../components/core/Page";
import { Panel } from "../components/core/Panel";
import { MultiSelectSearch } from "../components/core/MultiSelectSearch";
import { DeleteButton } from "../components/core/DeleteButton";
import { PlayerCompareSeasonBoxScores } from "../components/player/PlayerCompareSeasonBoxScores";
import { MultiSelect } from "../components/core/MultiSelect";
import { seasonString } from "../util/Format";
import { trpc } from "../util/tRPC";
import { PlayerCompareMeasurementsTable } from "../components/player/PlayerCompareMeasurementsTable";

interface PlayerLeagueSeasonObj {
  playerId?: string;
  league?: string;
  seasons?: string[];
}

export function PlayerComparePage() {
  const [queryParams, setQueryParams] = useQueryParams({
    players: withDefault(JsonParam, [
      {
        playerId: undefined,
        league: undefined,
        seasons: [],
      },
    ]) as QueryParamConfig<PlayerLeagueSeasonObj[]>,
  });

  const { players } = queryParams;

  const playerIds = players
    .map((p) => p.playerId)
    .filter((p) => !!p) as string[];

  return (
    <Page header={{ text: "Compare Players" }} title="Compare Players">
      <div>
        <Panel header={"Select Players"}>
          <PlayerSelect
            players={players}
            setPlayers={(pls) => setQueryParams({ players: pls })}
          />
        </Panel>
        {players && playerIds.length > 0 && (
          <PlayerCompareSeasonBoxPanel players={players} />
        )}
        {playerIds.length > 0 && (
          <PlayerCompareMeasurementsPanel playerIds={playerIds} />
        )}
      </div>
    </Page>
  );
}

function PlayerSelect(props: {
  players: PlayerLeagueSeasonObj[];
  setPlayers: (pls: PlayerLeagueSeasonObj[]) => void;
}) {
  const { players, setPlayers } = props;

  const updatePlayer = (p: PlayerLeagueSeasonObj, i: number) => {
    const playersCopy = [...players];
    playersCopy[i] = p;
    setPlayers(playersCopy);
  };

  return (
    <div>
      <Row>
        <Col>
          <Form.Label>Player</Form.Label>
        </Col>
        <Col>
          <Form.Label>League</Form.Label>
        </Col>
        <Col>
          <Form.Label>Seasons</Form.Label>
        </Col>
      </Row>
      {players.map((p, i) => (
        <PlayerSelector
          key={i}
          player={p}
          updatePlayer={(p: PlayerLeagueSeasonObj) => updatePlayer(p, i)}
          onDelete={() => {
            const playersCopy = [...players];
            playersCopy.splice(i, 1);
            setPlayers(playersCopy);
          }}
        />
      ))}
      <Row>
        <Col>
          <Button
            onClick={() => {
              const playersCopy = [...players];
              playersCopy.push({
                playerId: undefined,
                league: undefined,
                seasons: [],
              });
              setPlayers(playersCopy);
            }}
          >
            Add Player
          </Button>
        </Col>
      </Row>
    </div>
  );
}

function PlayerSelector(props: {
  player: PlayerLeagueSeasonObj;
  updatePlayer: (p: PlayerLeagueSeasonObj) => void;
  onDelete: () => void;
}) {
  const { player, updatePlayer, onDelete } = props;

  const { data: playerLeagueSeasons } =
    trpc.player.getPlayerLeagueSeasons.useQuery({
      playerId: player.playerId ? parseInt(player.playerId) : undefined,
    });

  return (
    <Row style={{ marginBottom: 12 }}>
      <Col>
        <MultiSelectSearch
          limit={1}
          selected={player.playerId ? [player.playerId] : []}
          values={[]}
          onChange={(vals: string[]) => {
            updatePlayer({ playerId: vals[0] });
          }}
        />
      </Col>
      <Col>
        <Form.Select
          value={player.league}
          onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => {
            updatePlayer({
              playerId: player.playerId,
              league: evt.target.value,
              seasons: undefined,
            });
          }}
        >
          <option value={undefined}></option>
          {(playerLeagueSeasons
            ? playerLeagueSeasons.filter(
                (val, i, self) =>
                  self.findIndex((v) => v.leagueKey === val.leagueKey) === i
              )
            : []
          ).map((o, i) => (
            <option key={i} value={o.leagueKey}>
              {o.League}
            </option>
          ))}
        </Form.Select>
      </Col>
      <Col>
        <div style={{ display: "flex", alignItems: "flex-start", gap: 8 }}>
          <div style={{ flexGrow: 1 }}>
            <MultiSelect
              selected={player.seasons || []}
              values={
                playerLeagueSeasons
                  ? playerLeagueSeasons
                      .filter((pls) => pls.leagueKey === player.league)
                      .map((pls) => {
                        return {
                          value: pls.Season.toString(),
                          label: `${seasonString(
                            pls.Season.toString()
                          )} - Age ${Math.floor(pls.age)}`,
                        };
                      })
                  : []
              }
              onChange={(vals: string[]) => {
                updatePlayer({
                  playerId: player.playerId,
                  league: player.league,
                  seasons: vals,
                });
              }}
            />
          </div>
          <DeleteButton onClick={onDelete} />
        </div>
      </Col>
    </Row>
  );
}

function PlayerCompareSeasonBoxPanel(props: {
  players: PlayerLeagueSeasonObj[];
}) {
  const { players } = props;
  const [boxScoresTab, setBoxScoresTab] = useState("perGame");

  const tabs = {
    onClick: (tabKey: string) => setBoxScoresTab(tabKey),
    active: boxScoresTab,
    tabs: {
      perGame: {
        label: "Per Game",
        content: (
          <div>
            <PlayerCompareSeasonBoxScores players={players} type="perGame" />
          </div>
        ),
      },
      total: {
        label: "Totals",
        content: (
          <div>
            <PlayerCompareSeasonBoxScores players={players} type="totals" />
          </div>
        ),
      },
      per100: {
        label: "Per 100 Poss",
        content: (
          <div>
            <PlayerCompareSeasonBoxScores players={players} type="per100Poss" />
          </div>
        ),
      },
    },
  };
  return <Panel tabs={tabs}></Panel>;
}

function PlayerCompareMeasurementsPanel(props: { playerIds: string[] }) {
  const { playerIds } = props;

  const { data: bios } = trpc.player.getPlayerBios.useQuery({ playerIds });

  const { data: modeledMeasurements } =
    trpc.player.getPlayerModeledMeasurements.useQuery({ playerIds });

  if (!bios || !modeledMeasurements) return null;

  return (
    <Panel header={"Measurements"}>
      <PlayerCompareMeasurementsTable
        modeledMeasurements={modeledMeasurements}
        bios={bios}
        showBoxPlots={true}
      />
    </Panel>
  );
}
