import React, { useMemo, useState } from "react";

import { Highlights } from "../../constants/AppConstants";
import { Table, SortingState, createColumnHelper } from "../core/Table";
import { MultiLeagueSeasonPer100Est } from "../../../shared/routers/TeamRouter";
import { PlayerTableCell, TeamTableCell } from "../core/TableCell";
import { decFormat, dec100Format, decFormat2 } from "../../util/Format";
import { PlayerStatColorDomains } from "../../constants/ColorDomains";

const columnHelper = createColumnHelper<MultiLeagueSeasonPer100Est>();

const colorDomain = PlayerStatColorDomains["per100Poss"];

export function TeamMultiLeagueSeasonBoxPer100Est(props: {
  data: MultiLeagueSeasonPer100Est[];
  showTeam?: boolean;
}) {
  const { data, showTeam } = props;
  const [sorting, setSorting] = useState<SortingState>();
  const columns = useMemo(() => {
    let g = 0;

    return [
      columnHelper.accessor("player", {
        header: () => "Player",
        cell: (info) => (
          <PlayerTableCell
            id={info.row.original.celticsID}
            name={info.getValue()}
          />
        ),
        meta: { group: g },
      }),
      columnHelper.accessor("team", {
        header: () => "Team",
        cell: (info) => (
          <TeamTableCell id={info.row.original.teamId} name={info.getValue()} />
        ),
        meta: { group: g },
      }),
      columnHelper.accessor("age", {
        header: () => "Age",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("min", {
        header: () => "MPG",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          colorDomain: colorDomain["min"],
          group: g++,
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("pts", {
        header: () => "PTS",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          colorDomain: colorDomain["pts"],
          group: g,
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("ast", {
        header: () => "AST",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          colorDomain: colorDomain["ast"],
          group: g,
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("blk", {
        header: () => "BLK",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          colorDomain: colorDomain["blk"],
          group: g,
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("stl", {
        header: () => "STL",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          colorDomain: colorDomain["stl"],
          group: g,
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("tov", {
        header: () => "TOV",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          colorDomain: colorDomain["tov"],
          group: g,
          highlights: Highlights.Min,
        },
      }),
      columnHelper.accessor("pf", {
        header: () => "PF",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          colorDomain: colorDomain["pf"],
          group: g++,
          highlights: Highlights.Min,
        },
      }),
      columnHelper.accessor("orPct", {
        header: () => "OR%",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          colorDomain: colorDomain["orbpct"],
          group: g,
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("drPct", {
        header: () => "DR%",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          colorDomain: colorDomain["drbpct"],
          group: g,
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("tovPct", {
        header: () => "TOV%",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          colorDomain: colorDomain["topct"],
          group: g++,
          highlights: Highlights.Min,
        },
      }),
      columnHelper.accessor("fg2Pct", {
        header: () => "2P%",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          colorDomain: colorDomain["2pct"],
          group: g,
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("fg3Pct", {
        header: () => "3P%",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          colorDomain: colorDomain["3pct"],
          group: g,
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("ftPct", {
        header: () => "FT%",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          colorDomain: colorDomain["ftpct"],
          group: g,
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("fg3aPct", {
        header: () => "3PA%",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          colorDomain: colorDomain["3papct"],
          group: g,
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("fga2", {
        header: () => "2PA",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          colorDomain: colorDomain["2pa"],
          group: g,
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("fga3", {
        header: () => "3PA",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          colorDomain: colorDomain["3pa"],
          group: g,
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("fta", {
        header: () => "FTA",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          colorDomain: colorDomain["fta"],
          group: g,
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("ts", {
        header: () => "TS%",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          colorDomain: colorDomain["ts"],
          group: g++,
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("ppp", {
        header: () => "PPP",
        cell: (info) => decFormat2(info.getValue()),
        meta: {
          colorDomain: colorDomain["ppp"],
          group: g,
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("usg", {
        header: () => "USG",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          colorDomain: colorDomain["usg"],
          group: g++,
          highlights: Highlights.Max,
        },
      }),
    ];
  }, []);

  const hiddenColumns = {
    team: !!showTeam,
  };

  return (
    <Table
      autoWidth={true}
      data={data}
      showColorOnHover={true}
      columns={columns}
      sorting={sorting}
      setSorting={setSorting}
      hiddenColumns={hiddenColumns}
    />
  );
}
