import React from "react";
import { Row, Col } from "react-bootstrap";

import { PlayerTableCell } from "../core/TableCell";
import { GameDetails, GameInjury } from "../../../shared/routers/GameRouter";
import { groupBy } from "../../../shared/util/Collections";

export function GameInjuryReport(props: {
  game: GameDetails;
  injuries: GameInjury[];
}) {
  const { game, injuries } = props;

  return (
    <Row>
      {["away", "home"].map((homeAway) => {
        const teamAbbr =
          homeAway === "home" ? game.homeTeamAbb : game.awayTeamAbb;
        const teamName = homeAway === "home" ? game.homeTeam : game.awayTeam;
        const teamInjuries = injuries.filter((i) => i.team === teamAbbr);
        const injuriesByListing = groupBy(
          teamInjuries,
          (ti) => ti.current_listing || "Not Submitted"
        );

        return (
          <Col key={homeAway} sm={12} md={6}>
            <table style={{ marginBottom: 24 }}>
              <thead>
                <tr>
                  <th style={{ textAlign: "center", fontSize: 20 }} colSpan={2}>
                    {teamName}
                  </th>
                </tr>
              </thead>
              {[
                "Not Submitted",
                "Available",
                "Probable",
                "Questionable",
                "Doubtful",
                "Out",
                "G League",
              ].map((type) => {
                const data = (injuriesByListing[type] || []).sort((a, b) =>
                  // Sort available players by MPG, otherwise just alphabetical.
                  type === "Available" || type === "Not Submitted"
                    ? b.mpg - a.mpg
                    : a.player.localeCompare(b.player)
                );
                if (data.length === 0) return null;
                return (
                  <tbody key={type}>
                    <tr>
                      <td>
                        <b>{type}</b>
                      </td>
                      <td></td>
                    </tr>
                    {data.map((d) => (
                      <tr key={d.celticsID}>
                        <td style={{ paddingRight: 8 }}>
                          <PlayerTableCell
                            id={d.celticsID}
                            name={d.player}
                            useSpans={true}
                          />
                        </td>
                        <td>
                          {type === "Available"
                            ? d.reason === "G League - Two-Way"
                              ? " Two Way"
                              : ""
                            : injuryReasonStr(d.reason)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                );
              })}
            </table>
          </Col>
        );
      })}
    </Row>
  );
}

function injuryReasonStr(reason: string | null) {
  if (reason === null) return "";
  return ` ${reason
    .replace("Injury/Illness - ", "")
    .replace("N/a;", "")
    .replace("G League - ", "")}`;
}
