import React from "react";

const MIN_NBA_IDS = 1610612737;
const MAX_ESPN_LOGO = 10000;

export function TeamLogo(props: {
  idsId?: number; // Team ID e.g. 1610612738 for Celtics.
  espnId?: number;
  isNcaa?: boolean;
  isGLeague?: boolean;
  dxId?: number;
  size?: number;
  inline?: boolean;
  className?: string;
  blur?: boolean;
}) {
  const { idsId, espnId, dxId, className, isNcaa, isGLeague, blur } = props;
  let { size, inline } = props;

  size = size || 80;
  inline = inline === undefined ? false : inline;

  const originalSize = 400;
  const scaleSize = size / originalSize;

  return (
    <div
      className={className}
      style={{
        filter: blur ? "url(#blur-and-scale)" : undefined,
        width: size,
        height: size,
        marginRight: inline ? size / 10 : undefined,
        display: inline ? "inline-block" : "block",
        overflow: "hidden",
      }}
    >
      {idsId && idsId >= MIN_NBA_IDS ? (
        isGLeague ? (
          <img
            width={size}
            height={size}
            src={`https://ak-static.cms.nba.com/wp-content/uploads/logos/nbagleague/${idsId}/primary/D/logo.svg`}
          />
        ) : (
          <div
            className={`team-logo logo-sprite logo-sprite-${idsId}`}
            style={{
              transform: `scale(${scaleSize}, ${scaleSize})`,
            }}
          ></div>
        )
      ) : espnId && isNcaa && espnId <= MAX_ESPN_LOGO ? (
        <img
          height={size}
          width={size}
          src={`https://a.espncdn.com/combiner/i?img=/i/teamlogos/ncaa/500/${espnId}.png`}
        />
      ) : dxId ? (
        <img
          style={{ padding: size * 0.1 }}
          height={size}
          width={size}
          src={`http://www.draftexpress.com/blue/graphics/logos/teams/${dxId}.png`}
        />
      ) : null}
    </div>
  );
}
