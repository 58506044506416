import React, { useState, useMemo } from "react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import {
  JsonParam,
  useQueryParams,
  QueryParamConfig,
  withDefault,
  DelimitedArrayParam,
  NumberParam,
} from "use-query-params";

import AppContext from "../../shared/AppContext";
import { Page } from "../components/core/Page";
import { Panel } from "../components/core/Panel";
import { MultiSelect } from "../components/core/MultiSelect";
import { Spinner } from "../components/core/Spinner";
import { ShotQueryResultsTable } from "../components/shots/ShotQueryResultsTable";
import { SortingState } from "../components/core/Table";
import { groupByMap } from "../constants/AppConstants";
import { ShotFilters, ShotAggregate } from "../../shared/routers/ShotRouter";
import { ShotFilterForm } from "../components/shots/ShotFilterForm";
import { ShotChartMakeMiss } from "../components/shots/ShotChartMakeMiss";
import { useDebounce } from "../util/Hooks";
import { VideoModal } from "../components/video/VideoModal";
import {
  SynergyEditorClip,
  shotToSynergyEditorClip,
} from "../components/video/utilities";
import { trpc } from "../util/tRPC";

const DETAILS_RESULT_LIMIT = 1_000;
const RESULT_LIMIT = 10_000;

const RESULT_LIMIT_MSG = `Your query produced a large return set. To improve
performance we've limited the return data to ${RESULT_LIMIT.toLocaleString()}
rows.`;

export function ShotExplorerPage() {
  const [clips, setClips] = useState<SynergyEditorClip[]>();
  const [videoFilters, setVideoFilters] = useState<ShotFilters>();

  const [leftData, setLeftData] = useState<ShotAggregate>();
  const [rightData, setRightData] = useState<ShotAggregate>();

  const [queryParams, setQueryParams] = useQueryParams({
    shotThreshold: withDefault(NumberParam, 0) as QueryParamConfig<number>,
    sorting: JsonParam as QueryParamConfig<SortingState>,
    filters: withDefault(JsonParam, {
      seasons: [AppContext.currentSeason],
    }) as QueryParamConfig<ShotFilters>,
    groupBy: withDefault(DelimitedArrayParam, ["shooter"]) as QueryParamConfig<
      string[]
    >,
  });

  const { shotThreshold, filters, groupBy, sorting } = queryParams;

  const debouncedFilters = useDebounce(filters, 500);

  const leftFilters = leftData
    ? dataToFilters(leftData, debouncedFilters)
    : undefined;
  const rightFilters = rightData
    ? dataToFilters(rightData, debouncedFilters)
    : undefined;

  const { data: leftShots } = trpc.shot.getShots.useQuery({
    limit: RESULT_LIMIT,
    filters: leftFilters,
  });

  const { data: rightShots } = trpc.shot.getShots.useQuery({
    limit: RESULT_LIMIT,
    filters: rightFilters,
  });

  const { data: shots, isLoading: shotsLoading } = trpc.shot.getShots.useQuery({
    limit: RESULT_LIMIT,
    filters: debouncedFilters,
  });

  const { data: aggregateShots, isLoading: aggregateShotsLoading } =
    trpc.shot.getAggregateShots.useQuery(
      {
        limit: RESULT_LIMIT,
        groupBy: groupBy,
        filters: debouncedFilters,
      },
      {
        onSuccess: (data) => {
          if (data[0]) {
            setLeftData(data[0]);
          } else {
            setLeftData(undefined);
          }
          if (data[1]) {
            setRightData(data[1]);
          } else {
            setRightData(undefined);
          }
        },
      }
    );

  trpc.shot.getShots.useQuery(
    {
      limit: DETAILS_RESULT_LIMIT,
      filters: videoFilters,
    },
    {
      enabled: !!videoFilters,
      onSuccess: (data) => {
        const clips = data
          .filter((d) => d.synergyUrl)
          .map(shotToSynergyEditorClip);
        if (clips.length === 0) {
          setClips(undefined);
          alert("All Synergy video clips are missing for the selected shots.");
          return;
        }
        setClips(clips);
      },
    }
  );

  const onDetails = (id: "left" | "right" | "", data: ShotAggregate) => {
    if (id === "left") {
      setLeftData(data);
    }
    if (id === "right") {
      setRightData(data);
    }
  };

  let resultsEl: JSX.Element | undefined;

  const filteredAggShots = useMemo<ShotAggregate[]>(() => {
    return aggregateShots
      ? aggregateShots.filter((s) => s.numShots >= shotThreshold)
      : [];
  }, [aggregateShots, shotThreshold]);

  if (shotsLoading || aggregateShotsLoading) {
    resultsEl = (
      <Spinner
        message={
          groupBy.length === 0
            ? "Fetching individual shots can be slow, try including additional filters to improve performance."
            : undefined
        }
      />
    );
  } else if (groupBy.length && aggregateShots && filteredAggShots) {
    const msg = `Minimum Shots: ${shotThreshold.toLocaleString()}
      (showing ${filteredAggShots.length.toLocaleString()} of
      ${aggregateShots.length.toLocaleString()} total rows)`;
    resultsEl = (
      <div>
        {aggregateShots.length === RESULT_LIMIT
          ? showWarning(RESULT_LIMIT_MSG)
          : showSuccess(
              `${aggregateShots.length.toLocaleString()} matching rows found.`
            )}
        <Row>
          <Col md={4}>
            <span>{msg}</span>
            <Form.Range
              min={0}
              max={1000}
              value={shotThreshold}
              onChange={(evt) => {
                setQueryParams({ shotThreshold: parseInt(evt.target.value) });
              }}
            />
          </Col>
        </Row>
        <ShotQueryResultsTable
          shotsAgg={filteredAggShots}
          groupBy={groupBy}
          onVideo={(data) =>
            setVideoFilters(dataToFilters(data, debouncedFilters))
          }
          onDetails={(id, data) => onDetails(id, data)}
          sorting={sorting}
          setSorting={(s) => setQueryParams({ sorting: s })}
        />
        <VideoModal
          title={"Shot Explorer"}
          show={!!clips}
          clips={clips || []}
          handleClose={() => {
            setClips(undefined);
            setVideoFilters(undefined);
          }}
          upDownClipSkip={true}
          showSynergyEditor={true}
        />
      </div>
    );
  } else if (groupBy.length === 0 && shots) {
    resultsEl = (
      <div>
        {shots.length === RESULT_LIMIT
          ? showWarning(RESULT_LIMIT_MSG)
          : showSuccess(
              `${shots.length.toLocaleString()} matching shots found.`
            )}
        <ShotQueryResultsTable
          shots={shots}
          sorting={sorting}
          setSorting={(s) => setQueryParams({ sorting: s })}
        />
      </div>
    );
  }

  return (
    <Page title={"Shot Explorer"} header={{ text: "Shot Explorer" }}>
      <Row>
        <Col md={12}>
          <Panel header={"Filters"}>
            <div>
              <ShotFilterForm
                filters={filters}
                onFilterChange={(f) => setQueryParams({ filters: f })}
              />
              <Form.Group>
                <Form.Label>Group By</Form.Label>
                <MultiSelect
                  values={groupByMap}
                  selected={groupBy}
                  onChange={(g) => setQueryParams({ groupBy: g })}
                />
              </Form.Group>
            </div>
          </Panel>
        </Col>
        <Col md={12}>
          <Panel header={"Results"}>{resultsEl}</Panel>
        </Col>
        <Col md={6}>
          {groupBy.length > 0 && leftShots && leftData && (
            <Panel header={headerFromGroupBy(groupBy, leftData)}>
              {leftShots.length === DETAILS_RESULT_LIMIT &&
                showWarning(
                  `Only showing the first ${DETAILS_RESULT_LIMIT.toLocaleString()} shots that match these criteria.`
                )}
              <ShotChartMakeMiss
                shots={leftShots}
                maxMarkWidth={12}
                shooterName={true}
              />
            </Panel>
          )}
        </Col>
        <Col md={6}>
          {groupBy.length > 0 && rightShots && rightData && (
            <Panel header={headerFromGroupBy(groupBy, rightData)}>
              {rightShots.length === DETAILS_RESULT_LIMIT &&
                showWarning(
                  `Only showing the first ${DETAILS_RESULT_LIMIT.toLocaleString()} shots that match these criteria.`
                )}
              <ShotChartMakeMiss
                shots={rightShots}
                maxMarkWidth={12}
                shooterName={true}
              />
            </Panel>
          )}
        </Col>
      </Row>
    </Page>
  );
}

// Create a panel header by using the group by strings.
function headerFromGroupBy(groupBy: string[], data: ShotAggregate) {
  const vals = groupBy
    .map(
      (gb) =>
        `${groupByMap.find((g) => g.value === gb)?.value}: ${
          data[gb as keyof ShotAggregate]
        }`
    )
    .filter((v) => !!v)
    .join(", ");
  return vals;
}

function showWarning(msg: string) {
  return <Alert variant="warning">{msg}</Alert>;
}

function showSuccess(msg: string) {
  return <Alert variant="success">{msg}</Alert>;
}

function dataToFilters(data: ShotAggregate, filters: ShotFilters) {
  const newFilters = { ...filters };

  if (data.shooterId) {
    newFilters.shooterIds = [data.shooterId.toString()];
  }
  if (data.passerId) {
    newFilters.passerIds = [data.passerId.toString()];
  }
  if (data.defenderId) {
    newFilters.defenderIds = [data.defenderId.toString()];
  }
  if (data.season) {
    newFilters.seasons = [data.season.toString()];
  }
  if (data.period) {
    newFilters.periods = [data.period.toString()];
  }
  if (data.oteamId) {
    newFilters.offTeamIds = [data.oteamId.toString()];
  }
  if (data.dteamId) {
    newFilters.defTeamIds = [data.dteamId.toString()];
  }
  if (data.positionShooter) {
    newFilters.shooterPositions = [data.positionShooter.toString()];
  }
  if (data.jointPosition) {
    newFilters.shooterTypicalPositions = [data.jointPosition.toString()];
  }
  if (data.positionDefender) {
    newFilters.defenderPositions = [data.positionDefender.toString()];
  }
  if (data.jointPositionDef) {
    newFilters.defenderTypicalPositions = [data.jointPositionDef.toString()];
  }
  if (data.generalShotType) {
    newFilters.generalShotTypes = [data.generalShotType.toString()];
  }
  if (data.specificShotType) {
    newFilters.specificShotTypes = [data.specificShotType.toString()];
  }
  if (data.driveDirection) {
    newFilters.driveDirections = [data.driveDirection.toString()];
  }
  if (data.direction) {
    newFilters.directions = [data.direction.toString()];
  }
  if (data.afterTimeout !== undefined) {
    newFilters.afterTimeout = data.afterTimeout;
  }
  if (data.fouled !== undefined) {
    newFilters.fouled = data.fouled;
  }
  if (data.blocked !== undefined) {
    newFilters.blocked = data.blocked;
  }
  if (data.putback !== undefined) {
    newFilters.putback = data.putback;
  }
  if (data.oreb2024 !== undefined) {
    newFilters.oreb2024 = data.oreb2024;
  }
  if (data.leftSide !== undefined) {
    newFilters.leftSide = data.leftSide;
  }
  if (data.transition !== undefined) {
    newFilters.transition = data.transition;
  }
  if (data.corner !== undefined) {
    newFilters.corner = data.corner;
  }
  if (data.aboveTheBreak !== undefined) {
    newFilters.aboveTheBreak = data.aboveTheBreak;
  }
  if (data.isThree !== undefined) {
    newFilters.isThree = data.isThree;
  }
  if (data.contestLevel) {
    newFilters.contestLevel = [data.contestLevel.toString()];
  }
  if (data.gameId) {
    newFilters.gameIds = [data.gameId.toString()];
  }
  if (data.made !== undefined) {
    newFilters.made = data.made;
  }
  if (data.isRegularSeason !== undefined) {
    newFilters.isPlayoffs = !data.isRegularSeason;
  }
  return newFilters;
}
