import React from "react";
import { Col, Row } from "react-bootstrap";

import { Panel } from "../../components/core/Panel";
import { Shot } from "../../../shared/routers/ShotRouter";
import {
  GameBoxScore,
  GameTeamReboundModel,
  GamePlayerReboundModel,
  CrashAttempt,
  GameExpectedStats,
  GameExpectedTurnover,
  GameAttackAvoid,
} from "../../../shared/routers/GameRouter";
import {
  TeamStats,
  TeamActionBreakdown,
} from "../../../shared/routers/TeamRouter";
import { GameBoxScoresTable } from "../../components/games/GameBoxScoresTable";
import { TeamCrashing } from "../../components/crashing/TeamCrashing";
import { GamePlayerReboundingLuck } from "../../components/games/GamePlayerReboundingLuck";
import { GameTeamReboundingLuck } from "../../components/games/GameTeamReboundingLuck";
import { GameTeamShootingLuck } from "../../components/games/GameTeamShootingLuck";
import { GamePlayerShootingLuck } from "../../components/games/GamePlayerShootingLuck";
import { GameTeamShootingLuckBreakdown } from "../../components/games/GameTeamShootingLuckBreakdown";
import { GameTeamStats } from "../../components/games/GameTeamStats";
import { GameTeamActionBreakdown } from "../../components/games/GameTeamActionBreakdown";
import { GameAttackAvoidTables } from "../../components/games/GameAttackAvoidTables";
import { TeamColorBox } from "../../components/team/TeamColorBox";
import { GameXPtsBreakdownTable } from "../../components/games/GameXPtsBreakdownTable";
import { GameExpectedTurnoversTable } from "../../components/games/GameExpectedTurnoversTable";
import { GameChanceStartTypes } from "../../components/games/GameChanceStartTypes";
import { Restrict } from "../../components/core/Restrict";
import { groupBy } from "../../../shared/util/Collections";
import {
  ChanceEfficiencyPerStartType,
  EffPerStartTypeForCompGame,
} from "../../../shared/routers/ChanceRouter";
import { trpc } from "../../util/tRPC";

export function GameSubPagePostgame(props: {
  filtered: boolean;
  home?: {
    teamid: number;
    teamCity: string;
    team: string;
    teamabbreviation: string;
    teamName: string;
  };
  away?: {
    teamid: number;
    teamCity: string;
    team: string;
    teamabbreviation: string;
    teamName: string;
  };
  fromDate: string;
  toDate: string;
  shots?: Shot[];
  leagueStats?: TeamStats[];
  awayTeamStats?: TeamStats[];
  homeTeamStats?: TeamStats[];
  gameExpectedStats?: GameExpectedStats[];
  boxscores?: GameBoxScore[];
  gameActions?: TeamActionBreakdown[];
  seasonActions?: TeamActionBreakdown[];
  teamRebounds?: GameTeamReboundModel[];
  crashAttempts?: CrashAttempt[];
  playerRebounds?: GamePlayerReboundModel[];
  expectedTovs?: GameExpectedTurnover[];
  attackAvoid?: GameAttackAvoid[];
  chanceStartTypeData?: ChanceEfficiencyPerStartType[];
  chanceStartTypeCompData?: EffPerStartTypeForCompGame[];
}) {
  const {
    filtered,
    home,
    away,
    fromDate,
    toDate,
    shots,
    leagueStats,
    awayTeamStats,
    homeTeamStats,
    gameExpectedStats,
    boxscores,
    gameActions,
    seasonActions,
    teamRebounds,
    crashAttempts,
    playerRebounds,
    expectedTovs,
    attackAvoid,
    chanceStartTypeData,
    chanceStartTypeCompData,
  } = props;

  const homeStats = homeTeamStats?.[0];
  const awayStats = awayTeamStats?.[0];

  if (!home || !away) return null;

  return (
    <div>
      <Row>
        <Col md={6}>
          {shots && (
            <TeamShootingLuckPanel
              home={home}
              away={away}
              fromDate={fromDate}
              toDate={toDate}
              shots={shots}
              filtered={filtered}
            />
          )}
        </Col>
        <Col md={6}>
          <Panel header={"xPts Breakdown"} filtered={filtered}>
            <GameXPtsBreakdownTable data={gameExpectedStats} />
          </Panel>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          {shots && (
            <PlayerShootingLuckPanel
              home={home}
              away={away}
              shots={shots}
              filtered={filtered}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <BoxScoresPanel data={boxscores} filtered={filtered} />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <TeamStatComparisonPanel
            home={home}
            away={away}
            leagueStats={leagueStats}
            awayTeamStats={awayTeamStats}
            homeTeamStats={homeTeamStats}
            filtered={filtered}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <OffensiveDefensiveBreakdownPanel
            home={home}
            away={away}
            gameActions={gameActions}
            seasonActions={seasonActions}
            filtered={filtered}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <TeamReboundsPanel
            teamRebounds={teamRebounds}
            home={home}
            away={away}
            filtered={filtered}
          />
        </Col>
        <Col md={6}>
          <GameCrashingPanel
            crashAttempts={crashAttempts}
            home={home}
            away={away}
            filtered={filtered}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <PlayerReboundsPanel
            playerRebounds={playerRebounds}
            home={home}
            away={away}
            filtered={filtered}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          {shots && homeStats && awayStats && (
            <TeamShootingLuckBreakdownPanel
              shots={shots}
              home={home}
              away={away}
              homeStats={homeStats}
              awayStats={awayStats}
              filtered={filtered}
            />
          )}
        </Col>
        <Col md={6}>
          <Restrict roles={["bia"]}>
            <Panel header={"xTOV Breakdown - [BIA ONLY]"} filtered={filtered}>
              <GameExpectedTurnoversTable expectedTovs={expectedTovs} />
            </Panel>
          </Restrict>
        </Col>
      </Row>
      <Row>
        <Col>
          <Restrict roles={["bia"]}>
            <Panel
              header={"WIP: Attack Avoid - [BIA ONLY]"}
              filtered={filtered}
            >
              <GameAttackAvoidTables data={attackAvoid} />
            </Panel>
          </Restrict>
        </Col>
      </Row>
      <Row>
        <Col>
          <Panel
            header="Possession Start Type Efficiency Breakdown"
            panelID="startTypeEfficiency"
            filtered={filtered}
          >
            <GameChanceStartTypes
              data={chanceStartTypeData}
              compData={chanceStartTypeCompData}
              home={home}
              away={away}
            />
          </Panel>
        </Col>
      </Row>
    </div>
  );
}

function TeamShootingLuckPanel(props: {
  filtered: boolean;
  home: {
    teamid: number;
    teamCity: string;
    team: string;
    teamabbreviation: string;
  };
  away: {
    teamid: number;
    teamCity: string;
    team: string;
    teamabbreviation: string;
  };
  fromDate: string;
  toDate: string;
  shots: Shot[];
}) {
  const { filtered, home, away, shots, fromDate, toDate } = props;
  return (
    <Panel
      panelID="teamShotQuality"
      header={"Team Shot Quality"}
      filtered={filtered}
    >
      <GameTeamShootingLuck
        data={shots}
        home={home}
        away={away}
        fromDate={fromDate}
        toDate={toDate}
      />
    </Panel>
  );
}

function PlayerShootingLuckPanel(props: {
  filtered: boolean;
  home: {
    teamid: number;
    teamCity: string;
    team: string;
  };
  away: {
    teamid: number;
    teamCity: string;
    team: string;
  };
  shots: Shot[];
}) {
  const { filtered, home, away, shots } = props;

  return (
    <Panel
      panelID="playerShotQuality"
      header={"Player Shot Quality"}
      filtered={filtered}
    >
      <GamePlayerShootingLuck data={shots} home={home} away={away} />
    </Panel>
  );
}

function BoxScoresPanel(props: { data?: GameBoxScore[]; filtered: boolean }) {
  const { data, filtered } = props;

  if (!data) return null;

  const dataByTeam = groupBy(data || [], (d) => d.homeAway);

  return (
    <Panel panelID="boxScores" header={"Box Scores"} filtered={filtered}>
      <>
        {/* Sort home before away. */}
        {Object.entries(dataByTeam)
          .sort((a, b) => (a[0] > b[0] ? -1 : 1))
          .map(([key, teamData]) => (
            <GameBoxScoresTable data={teamData} key={key} />
          ))}
      </>
    </Panel>
  );
}

function TeamStatComparisonPanel(props: {
  filtered: boolean;
  home: {
    teamid: number;
    teamCity: string;
    team: string;
    teamabbreviation: string;
  };
  away: {
    teamid: number;
    teamCity: string;
    team: string;
    teamabbreviation: string;
  };
  leagueStats?: TeamStats[];
  homeTeamStats?: TeamStats[];
  awayTeamStats?: TeamStats[];
}) {
  const { filtered, home, away, leagueStats, homeTeamStats, awayTeamStats } =
    props;

  const homeStats = homeTeamStats && homeTeamStats[0];
  const awayStats = awayTeamStats && awayTeamStats[0];

  // TODO(chrisbu): Move this up to page top level.
  const { data: referenceGames } =
    trpc.league.getTeamStatsReferenceGames.useQuery();

  return (
    <Panel
      panelID="teamStatComparison"
      header={"Team Stat Comparison"}
      filtered={filtered}
    >
      {homeStats && awayStats && leagueStats && referenceGames && (
        <GameTeamStats
          home={home}
          away={away}
          homeStats={homeStats}
          awayStats={awayStats}
          leagueStats={leagueStats}
          // Cast is safe b/c the only data we need from referenceGames
          // is in the type.
          leagueGames={referenceGames as TeamStats[]}
        />
      )}
    </Panel>
  );
}

function OffensiveDefensiveBreakdownPanel(props: {
  filtered: boolean;
  home: {
    teamid: number;
    teamCity: string;
    team: string;
    teamabbreviation: string;
    teamName: string;
  };
  away: {
    teamid: number;
    teamCity: string;
    team: string;
    teamabbreviation: string;
    teamName: string;
  };
  gameActions?: TeamActionBreakdown[];
  seasonActions?: TeamActionBreakdown[];
}) {
  const { filtered, home, away, gameActions, seasonActions } = props;

  if (!gameActions || !seasonActions) return null;

  return (
    <Panel
      panelID="breakdown"
      header={"Offensive and Defensive Breakdown"}
      filtered={filtered}
    >
      <GameTeamActionBreakdown
        home={home}
        away={away}
        gameActions={gameActions}
        seasonActions={seasonActions}
      />
    </Panel>
  );
}

function TeamReboundsPanel(props: {
  filtered: boolean;
  teamRebounds?: GameTeamReboundModel[];
  home: {
    team: string;
    teamid: number;
    teamName: string;
    teamCity: string;
    teamabbreviation: string;
  };
  away: {
    team: string;
    teamid: number;
    teamName: string;
    teamCity: string;
    teamabbreviation: string;
  };
}) {
  const { filtered, teamRebounds, home, away } = props;

  return (
    <Panel
      panelID="teamRebounds"
      header={"Team Rebounding"}
      filtered={filtered}
    >
      {teamRebounds && (
        <div>
          <TeamColorBox teamId={home.teamid} oppTeamId={away.teamid} />
          {home.teamCity}{" "}
          <TeamColorBox teamId={away.teamid} oppTeamId={home.teamid} />
          {away.teamCity}
          <GameTeamReboundingLuck data={teamRebounds} home={home} away={away} />
        </div>
      )}
    </Panel>
  );
}

function PlayerReboundsPanel(props: {
  filtered: boolean;
  playerRebounds?: GamePlayerReboundModel[];
  home: {
    teamid: number;
    teamCity: string;
    team: string;

    teamabbreviation: string;
  };
  away: {
    teamid: number;
    teamCity: string;
    team: string;
    teamabbreviation: string;
  };
}) {
  const { filtered, playerRebounds, home, away } = props;

  return (
    <Panel
      panelID="playerRebounds"
      header={"Player Rebounding"}
      filtered={filtered}
    >
      {playerRebounds && (
        <GamePlayerReboundingLuck
          data={playerRebounds}
          home={home}
          away={away}
        />
      )}
    </Panel>
  );
}

function TeamShootingLuckBreakdownPanel(props: {
  filtered: boolean;
  shots: Shot[];
  home: {
    teamid: number;
    teamCity: string;
    team: string;

    teamabbreviation: string;
  };
  away: {
    teamid: number;
    teamCity: string;
    team: string;

    teamabbreviation: string;
  };
  homeStats: TeamStats;
  awayStats: TeamStats;
}) {
  const { filtered, shots, home, away, homeStats, awayStats } = props;
  return (
    <Panel
      panelID="teamShotQualityBreakdown"
      header={"Team Shot Quality Breakdown"}
      filtered={filtered}
    >
      <GameTeamShootingLuckBreakdown
        data={shots}
        home={home}
        away={away}
        awayFTPct={awayStats.ftPct}
        homeFTPct={homeStats.ftPct}
      />
    </Panel>
  );
}

function GameCrashingPanel(props: {
  filtered: boolean;
  crashAttempts?: CrashAttempt[];
  home: {
    teamid: number;
    teamCity: string;
    team: string;

    teamabbreviation: string;
  };
  away: {
    teamid: number;
    teamCity: string;
    team: string;

    teamabbreviation: string;
  };
}) {
  const { filtered, crashAttempts, home, away } = props;

  if (crashAttempts === undefined || crashAttempts.length === 0) return null;

  return (
    <Panel header="Team Crashing" panelID="teamCrashing" filtered={filtered}>
      <TeamCrashing
        data={crashAttempts}
        teams={[
          { teamName: home.team, teamId: home.teamid },
          { teamName: away.team, teamId: away.teamid },
        ]}
      />
    </Panel>
  );
}
