import React, { useContext } from "react";
import {
  ButtonGroup,
  DropdownButton,
  Dropdown,
  Col,
  Form,
  Row,
} from "react-bootstrap";
import { createStyles, makeStyles } from "@material-ui/styles";

import { Periods } from "../../constants/AppConstants";
import { MultiSelect } from "../core/MultiSelect";
import AppContext from "../../../shared/AppContext";
import { TeamContext } from "../../TeamContext";
import { PossessionFilters } from "../../../shared/routers/PossessionRouter";
import { BooleanInput } from "../core/BooleanInput";
import { WowyLineupInput } from "../query/WowyLineupInput";
import { Slider } from "../core/Slider";
import { pctFormat } from "../../util/Format";
import { NumberInput } from "../core/NumberInput";
import { Restrict } from "../core/Restrict";

const useStyles = makeStyles(() =>
  createStyles({
    filters: {
      borderBottom: "1px solid #eee",
      paddingBottom: 15,
      marginBottom: 15,
    },
    group: {
      background: "#f4f4f4",
      borderRadius: 4,
      margin: 4,
      padding: 12,
      "& .row": {
        marginBottom: 12,
      },
    },
    datePicker: {
      width: "auto",
      display: "inline-block",
    },
  })
);

export function PossessionFilterForm(props: {
  filters: PossessionFilters;
  onFilterChange: (f: PossessionFilters) => void;
}) {
  const { filters, onFilterChange } = props;
  const classes = useStyles();

  // Data to populate filters.
  const teams = useContext(TeamContext).teams;

  return (
    <Form>
      <Form.Group className={classes.filters}>
        <Row>
          <Col className={classes.group}>
            <Row>
              <Col>
                <Form.Label>Season</Form.Label>
                <MultiSelect
                  values={AppContext.seasons.map((s) => {
                    return { value: s.value.toString(), label: s.label };
                  })}
                  selected={filters.season?.map((s) => s.toString()) || []}
                  onChange={(vals) =>
                    onFilterChange(
                      Object.assign({}, filters, {
                        season: vals.length
                          ? vals.map((v) => parseInt(v))
                          : undefined,
                      })
                    )
                  }
                />
              </Col>
              <Col>
                <Form.Label>Period</Form.Label>
                <MultiSelect
                  values={Object.keys(Periods).map((p) => {
                    return { value: p, label: Periods[parseInt(p)] || "" };
                  })}
                  selected={filters.period?.map((p) => p.toString()) || []}
                  onChange={(vals) =>
                    onFilterChange(
                      Object.assign({}, filters, {
                        period: vals.length
                          ? vals.map((v) => parseInt(v))
                          : undefined,
                      })
                    )
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col className={classes.group}>
            <Row>
              <Col>
                <div>
                  <Form.Label>Date</Form.Label>
                </div>
                <Form.Control
                  type="date"
                  className={classes.datePicker}
                  value={filters.fromDate}
                  onChange={(evt) => {
                    onFilterChange(
                      Object.assign({}, filters, {
                        fromDate: evt.target.value || undefined,
                      })
                    );
                  }}
                />
                {" to "}
                <Form.Control
                  type="date"
                  className={classes.datePicker}
                  value={filters.toDate}
                  onChange={(evt) => {
                    onFilterChange(
                      Object.assign({}, filters, {
                        toDate: evt.target.value || undefined,
                      })
                    );
                  }}
                />
              </Col>
              <Col>
                <Form.Label>Leverage</Form.Label>
                <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                  <div>
                    <Slider
                      numSteps={200}
                      value={{
                        min: filters.fromLeverage || 0,
                        max: filters.toLeverage || 200,
                      }}
                      onChange={(v: { min: number; max: number }) =>
                        onFilterChange({
                          ...filters,
                          fromLeverage: v.min,
                          toLeverage: v.max,
                        })
                      }
                    />
                  </div>
                  <div>
                    <DropdownButton as={ButtonGroup} title="Jump To">
                      <>
                        <Dropdown.Item
                          onClick={() => {
                            onFilterChange({
                              ...filters,
                              fromLeverage: 0,
                              toLeverage: 200,
                            });
                          }}
                        >
                          All Possessions
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            onFilterChange({
                              ...filters,
                              fromLeverage: 2,
                              toLeverage: 200,
                            });
                          }}
                        >
                          No Garbage Time
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            onFilterChange({
                              ...filters,
                              fromLeverage: 15,
                              toLeverage: 200,
                            });
                          }}
                        >
                          Close Game
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            onFilterChange({
                              ...filters,
                              fromLeverage: 50,
                              toLeverage: 200,
                            });
                          }}
                        >
                          High Leverage
                        </Dropdown.Item>
                      </>
                    </DropdownButton>
                  </div>
                </div>
                <div>{leverageString(filters)}</div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className={classes.group}>
            <Row>
              <Col>
                <Form.Label>Team</Form.Label>
                <MultiSelect
                  limit={1}
                  values={teams.map((t) => {
                    return {
                      label: `${t.teamcity} ${t.teamname}`,
                      value: t.teamid.toString(),
                    };
                  })}
                  selected={filters.teamId?.map((s) => s.toString()) || []}
                  onChange={(vals) =>
                    onFilterChange({
                      ...filters,
                      wowyLineup: undefined,
                      teamId: vals.length
                        ? vals.map((m) => parseInt(m))
                        : undefined,
                    })
                  }
                />
              </Col>
              <Restrict roles={["bia"]}>
                <Col>
                  <Form.Label># Bigs [BIA ONLY]</Form.Label>
                  <NumberInput
                    value={filters.numBigs || {}}
                    onChange={(v) => {
                      onFilterChange({ ...filters, numBigs: v || undefined });
                    }}
                  />
                </Col>
                <Col>
                  <Form.Label># Fives [BIA ONLY]</Form.Label>
                  <NumberInput
                    value={filters.numFives || {}}
                    onChange={(v) => {
                      onFilterChange({ ...filters, numFives: v || undefined });
                    }}
                  />
                </Col>
              </Restrict>
            </Row>
            <Row>
              <Col>
                <Form.Label>Lineup</Form.Label>
                <WowyLineupInput
                  disabled={filters.teamId === undefined}
                  teamId={filters.teamId ? filters.teamId[0] : undefined}
                  seasons={filters.season}
                  value={filters.wowyLineup}
                  setValue={(val) =>
                    onFilterChange({ ...filters, wowyLineup: val })
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col className={classes.group}>
            <Row>
              <Col>
                <Form.Label>Opponent</Form.Label>
                <MultiSelect
                  values={teams.map((t) => {
                    return {
                      label: `${t.teamcity} ${t.teamname}`,
                      value: t.teamid.toString(),
                    };
                  })}
                  selected={filters.oppTeamId?.map((s) => s.toString()) || []}
                  onChange={(vals) =>
                    onFilterChange(
                      Object.assign({}, filters, {
                        oppTeamId: vals.length
                          ? vals.map((m) => parseInt(m))
                          : undefined,
                      })
                    )
                  }
                />
              </Col>
              <Restrict roles={["bia"]}>
                <Col>
                  <Form.Label># Bigs [BIA ONLY]</Form.Label>
                  <NumberInput
                    value={filters.oppNumBigs || {}}
                    onChange={(v) => {
                      onFilterChange({
                        ...filters,
                        oppNumBigs: v || undefined,
                      });
                    }}
                  />
                </Col>
                <Col>
                  <Form.Label># Fives [BIA ONLY]</Form.Label>
                  <NumberInput
                    value={filters.oppNumFives || {}}
                    onChange={(v) => {
                      onFilterChange({
                        ...filters,
                        oppNumFives: v || undefined,
                      });
                    }}
                  />
                </Col>
              </Restrict>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label>Playoffs</Form.Label>
            <BooleanInput
              name="isPlayoff"
              value={boolToStringFix(filters.isPlayoff)}
              onChange={(v) => {
                onFilterChange({
                  ...filters,
                  isPlayoff: stringToBoolFix(v),
                });
              }}
            />
          </Col>
          <Col>
            <Form.Label>Transition</Form.Label>
            <BooleanInput
              name="transition"
              value={boolToStringFix(filters.transition)}
              onChange={(v) => {
                onFilterChange({
                  ...filters,
                  transition: stringToBoolFix(v),
                });
              }}
            />
          </Col>
          <Col>
            <Form.Label>Turnover</Form.Label>
            <BooleanInput
              name="turnover"
              value={boolToStringFix(filters.turnover)}
              onChange={(v) => {
                onFilterChange({
                  ...filters,
                  turnover: stringToBoolFix(v),
                });
              }}
            />
          </Col>
          <Col>
            <Form.Label>Zone</Form.Label>
            <BooleanInput
              name="zone"
              value={boolToStringFix(filters.zone)}
              onChange={(v) => {
                onFilterChange({
                  ...filters,
                  zone: stringToBoolFix(v),
                });
              }}
            />
          </Col>
        </Row>
      </Form.Group>
    </Form>
  );
}

function leverageString(filters: PossessionFilters) {
  const fromLeverage = (filters.fromLeverage || 0) / 1_000;
  const toLeverage = (filters.toLeverage || 200) / 1_000;

  return `${pctFormat(fromLeverage)} - ${pctFormat(toLeverage)}`;
}

// TODO(chrisbu): Remove these functions when the shot explorer is migrated to
// tRPC and no longer use strings for boolean values.
function boolToStringFix(val?: boolean) {
  if (val === undefined) return undefined;
  return val ? "1" : "0";
}

function stringToBoolFix(val?: string | number) {
  if (val === undefined) return undefined;
  return val === "1" || val === 1;
}
