import React, { useMemo, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { FaPlayCircle } from "react-icons/fa";

import { Page } from "../components/core/Page";
import { trpc } from "../util/tRPC";
import { Panel } from "../components/core/Panel";
import { VideoModal } from "../components/video/VideoModal";
import {
  Table,
  createColumnHelper,
  SortingState,
} from "../components/core/Table";
import { AggPlayerReads } from "../../shared/routers/PlayCallRouter";
import { Highlights } from "../constants/AppConstants";
import { decFormat2, pctFormat } from "../util/Format";
import { TruncatedTableCell } from "../components/core/TableCell";

const colorDomainXPPP = [1.1, 1.3];
const colorDomainPPP = [1, 2];
const colorDomainXPPS = [0.86, 1.4];
const colorDomainPPS = [0.77, 1.46];
const colorDomainTov = [0.2, 0.07];

export function ReadsPage() {
  const { data } = trpc.playCall.getAggPlayerReads.useQuery();

  if (!data) return null;

  return (
    <Page header={{ text: "Player Reads" }} title="Player Reads">
      <Row>
        {["actions", "reads"].map((type) => (
          <Col key={type}>
            <Panel header={type === "actions" ? "By Action" : "By Read"}>
              <ActionsTable
                type={type}
                data={data.filter((d) =>
                  type === "actions"
                    ? d.actionOrRead === "action"
                    : d.actionOrRead === "read"
                )}
              />
            </Panel>
          </Col>
        ))}
      </Row>
    </Page>
  );
}

const columnHelper = createColumnHelper<AggPlayerReads>();

function ActionsTable(props: { data: AggPlayerReads[]; type: string }) {
  const { data, type } = props;
  const [playerId, setPlayerId] = useState(0);
  const [sorting, setSorting] = useState<SortingState>();

  const [videoFilters, setVideoFilters] = useState<{
    playerId: number | null;
    actionOrRead: string | null;
    name: string | null;
    rightRead: number | null;
  }>({
    playerId: null,
    actionOrRead: null,
    name: null,
    rightRead: null,
  });

  const { data: videos } =
    trpc.playCall.getPlayerReadVideos.useQuery(videoFilters);

  const filteredData = data.filter((d) => d.playerId === playerId);

  const players = useMemo(() => {
    const returnSet: Record<
      string,
      { playerId: number; player: string; num: number }
    > = {};
    for (const d of data) {
      const entry = returnSet[d.playerId];
      if (d.playerId && !entry) {
        returnSet[d.playerId] = {
          playerId: d.playerId,
          player: d.playerName,
          num: d.num,
        };
      } else if (entry) {
        entry.num += d.num;
      }
    }
    return Object.values(returnSet).sort((a, b) => (a.num > b.num ? -1 : 1));
  }, [data]);

  const columns = useMemo(() => {
    let g = 0;
    return [
      columnHelper.accessor("type", {
        header: type === "actions" ? "Action" : "Read",
        cell: (info) => (
          <TruncatedTableCell width={140} tooltip={info.getValue()}>
            {info.getValue()}
          </TruncatedTableCell>
        ),
        meta: { group: g++, textAlign: "left" },
      }),
      columnHelper.accessor("num", {
        header: "#",
        meta: { group: g++, highlights: Highlights.Max },
      }),
      columnHelper.accessor("rightPct", {
        header: "Right%",
        cell: (info) => pctFormat(info.getValue()),
        meta: { group: g++, highlights: Highlights.Max },
      }),
      columnHelper.accessor("xPPP", {
        cell: (info) => decFormat2(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: colorDomainXPPP,
          heatmap: true,
        },
      }),
      columnHelper.accessor("PPP", {
        cell: (info) => decFormat2(info.getValue()),
        meta: {
          group: g++,
          highlights: Highlights.Max,
          colorDomain: colorDomainPPP,
        },
      }),
      columnHelper.accessor("xPPS", {
        cell: (info) => decFormat2(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: colorDomainXPPS,
        },
      }),
      columnHelper.accessor("PPS", {
        cell: (info) => decFormat2(info.getValue()),
        meta: {
          group: g++,
          highlights: Highlights.Max,
          colorDomain: colorDomainPPS,
        },
      }),
      columnHelper.accessor("xTovPct", {
        header: "xTOV%",
        cell: (info) => pctFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Min,
          colorDomain: colorDomainTov,
        },
      }),
      columnHelper.accessor("tovPct", {
        header: "TOV%",
        cell: (info) => pctFormat(info.getValue()),
        meta: {
          group: g++,
          highlights: Highlights.Min,
          colorDomain: colorDomainTov,
        },
      }),
      columnHelper.group({
        id: "rightOnly",
        meta: { group: g },
        header: "Right Read",
        columns: [
          columnHelper.accessor("xPPPRightOnly", {
            header: () => "xPPP",
            cell: (info) => decFormat2(info.getValue()),
            meta: {
              highlights: Highlights.Max,
              group: g,
              colorDomain: colorDomainXPPP,
            },
          }),
          columnHelper.accessor("PPPRightOnly", {
            header: () => "PPP",
            cell: (info) => decFormat2(info.getValue()),
            meta: {
              highlights: Highlights.Max,
              group: g,
              colorDomain: colorDomainPPP,
            },
          }),
          columnHelper.accessor("numRightVideo", {
            header: () => "",
            cell: (info) =>
              info.getValue() > 0 ? (
                <a
                  href="#"
                  onClick={() =>
                    setVideoFilters({
                      playerId: playerId,
                      actionOrRead: type === "actions" ? "action" : "read",
                      name: info.row.original.type,
                      rightRead: 1,
                    })
                  }
                >
                  {info.getValue()} <FaPlayCircle />
                </a>
              ) : (
                ""
              ),
            meta: { group: g++ },
          }),
        ],
      }),
      columnHelper.group({
        id: "wrongOnly",
        meta: { group: g },
        header: "Wrong Read",
        columns: [
          columnHelper.accessor("xPPPWrongOnly", {
            header: () => "xPPP",
            cell: (info) => decFormat2(info.getValue()),
            meta: {
              highlights: Highlights.Max,
              group: g,
              colorDomain: colorDomainXPPP,
            },
          }),
          columnHelper.accessor("PPPWrongOnly", {
            header: () => "PPP",
            cell: (info) => decFormat2(info.getValue()),
            meta: {
              highlights: Highlights.Max,
              group: g,
              colorDomain: colorDomainPPP,
            },
          }),
          columnHelper.accessor("numWrongVideo", {
            header: () => "",
            cell: (info) =>
              info.getValue() > 0 ? (
                <a
                  href="#"
                  onClick={() =>
                    setVideoFilters({
                      playerId: playerId,
                      actionOrRead: type === "actions" ? "action" : "read",
                      name: info.row.original.type,
                      rightRead: 0,
                    })
                  }
                >
                  {info.getValue()} <FaPlayCircle />
                </a>
              ) : (
                ""
              ),
            meta: { group: g++ },
          }),
        ],
      }),
    ];
  }, [playerId, type]);

  return (
    <div>
      <Form.Select
        style={{
          width: "auto",
          display: "inline",
          marginRight: 10,
        }}
        value={playerId}
        onChange={(e) => setPlayerId(parseInt(e.target.value))}
      >
        <option value={0}>All Players</option>
        <optgroup label="-----------"></optgroup>
        {players.map((p) => {
          return (
            <option key={p.player} value={p.playerId}>
              {p.playerId === -1 ? "Unknown" : p.player}
            </option>
          );
        })}
      </Form.Select>
      <Table
        data={filteredData.sort((a, b) => (a.num > b.num ? -1 : 1))}
        columns={columns}
        autoWidth={true}
        sorting={sorting}
        setSorting={setSorting}
        virtualScroll={true}
        showColorOnHover={true}
        showRowIndex={false}
      />
      <VideoModal
        clips={(videos || []).map((v) => {
          return {
            label: `${v.playerName} ${v.action} ${v.read}`,
            url: v.synergyUrl,
          };
        })}
        show={videos ? videos.length > 0 : false}
        title={videoTitleStr(videoFilters)}
        handleClose={() => {
          setVideoFilters({
            playerId: null,
            actionOrRead: null,
            name: null,
            rightRead: null,
          });
        }}
        upDownClipSkip={true}
        showSynergyEditor={false}
      />
    </div>
  );
}

function videoTitleStr(filters: {
  playerId: number | null;
  actionOrRead: string | null;
  name: string | null;
  rightRead: number | null;
}) {
  const { playerId, actionOrRead, name, rightRead } = filters;
  if (
    playerId === null ||
    actionOrRead === null ||
    name === null ||
    rightRead === null
  )
    return "";

  const rightReadStr = rightRead === 1 ? "Right" : "Wrong";
  return `${rightReadStr} ${name} Reads`;
}
