import React, { useContext } from "react";
import { scaleLinear, rgb } from "d3";

import { generateDomain } from "../../util/Util";
import { decFormat, decFormat2, dec100Format } from "../../util/Format";
import { TeamStats } from "../../../shared/routers/TeamRouter";
import { UserPreferenceContext } from "../../UserContext";
import { tableColorSchemes } from "../../constants/ChartConstants";
import { useWindowSize } from "../../util/Hooks";

export function TeamStatRankings(props: {
  leagueData: TeamStats[];
  teamId: number;
}) {
  const { leagueData, teamId } = props;
  const colorPref = useContext(UserPreferenceContext)["Color Scheme"] || 0;
  const colorScheme = tableColorSchemes[colorPref] || [];
  const teamData = leagueData.find((t) => t.teamId === teamId);

  const isMobile = useWindowSize().width < 768;

  // If there was no row for this team it means that there was no data that
  // matched the specific team filters. Examples of this would be setting the
  // filters to be "playoff only" for a team with no playoff games or choosing
  // a specific opponent that this team hasn't played yet.
  if (teamData === undefined) {
    return (
      <div>No games match the currently selected filters for this team.</div>
    );
  }

  const colorScale = scaleLinear<string, string>()
    .domain(generateDomain(30, 1, colorScheme.length))
    .range(colorScheme);

  const pd = prepareData(leagueData, teamId, teamData);
  const rowHeight = 40;

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: 60,
      }}
    >
      {pd.map((col, i) => (
        <div
          key={i}
          style={{
            display: "flex",
            width: isMobile ? "100%" : undefined,
          }}
        >
          <div style={{ flex: 1 }}>
            <div
              style={{
                borderBottom: "2px solid #ddd",
                fontWeight: 600,
                paddingBottom: 4,
                paddingLeft: 4,
              }}
            >
              Stat
            </div>
            {col.map((row) => (
              <div
                key={row.label}
                style={{
                  height: rowHeight,
                  fontWeight: 600,
                  lineHeight: `${rowHeight}px`,
                  minWidth: 72,
                  paddingRight: 4,
                  paddingLeft: 4,
                }}
              >
                {row.label}
              </div>
            ))}
          </div>
          <div style={{ flex: isMobile ? 1 : undefined }}>
            <div
              style={{
                borderBottom: "2px solid #ddd",
                fontWeight: 600,
                paddingBottom: 4,
                paddingLeft: 4,
              }}
            >
              Off
            </div>
            {col.map((row) => (
              <div key={row.label} style={{ height: rowHeight }}>
                <ValueAndRank
                  rowHeight={rowHeight}
                  value={row.offValue}
                  rank={row.offRank}
                  fmt={row.fmt}
                  colorScale={colorScale}
                />
              </div>
            ))}
          </div>
          <div style={{ flex: isMobile ? 1 : undefined }}>
            <div
              style={{
                borderBottom: "2px solid #ddd",
                fontWeight: 600,
                paddingBottom: 4,
                paddingLeft: 4,
              }}
            >
              DEF
            </div>
            {col.map((row) => (
              <div key={row.label} style={{ height: rowHeight }}>
                <ValueAndRank
                  rowHeight={rowHeight}
                  value={row.defValue}
                  rank={row.defRank}
                  fmt={row.fmt}
                  colorScale={colorScale}
                />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

function ValueAndRank(props: {
  rowHeight: number;
  value: number | null;
  rank: number | null;
  fmt: (n: number | null) => string;
  colorScale: (n: number) => string;
}) {
  const { rowHeight, value, rank, fmt, colorScale } = props;

  const backgroundColor = rgb(rank === null ? "white" : colorScale(rank));
  backgroundColor.opacity = 0.8;
  const textColor = "#000";

  const margin = 4;
  const lineHeight = rowHeight - 2 * margin;

  return (
    <>
      <span
        style={{
          opacity: 0.7,
          width: 24,
          fontSize: 12,
          display: "inline-block",
          textAlign: "center",
        }}
      >
        {rank}
      </span>{" "}
      <span
        style={{
          textAlign: "center",
          lineHeight: `${lineHeight}px`,
          height: lineHeight,
          width: lineHeight,
          borderRadius: 4,
          display: "inline-block",
          fontVariantNumeric: "tabular-nums",
          backgroundColor: backgroundColor.toString(),
          color: textColor,
          margin,
          marginRight: 8,
          fontSize: 12,
        }}
      >
        {fmt(value)}
      </span>
    </>
  );
}

function prepareData(
  leagueData: TeamStats[],
  teamId: number,
  teamData: TeamStats
) {
  const factors1 = [
    {
      label: "xPPP",
      fmt: decFormat2,
      offValue: teamData.xPPP,
      offRank: rankForStat("xPPP", teamId, leagueData),
      defValue: teamData.xPPPOpp,
      defRank: rankForStat("xPPPOpp", teamId, leagueData, true),
    },
    {
      label: "PPP",
      fmt: decFormat2,
      offValue: teamData.PPP,
      offRank: rankForStat("PPP", teamId, leagueData),
      defValue: teamData.PPPOpp,
      defRank: rankForStat("PPPOpp", teamId, leagueData, true),
    },

    {
      label: "xPPS",
      fmt: decFormat2,
      offValue: teamData.xPPS,
      offRank: rankForStat("xPPS", teamId, leagueData),
      defValue: teamData.xPPSOpp,
      defRank: rankForStat("xPPSOpp", teamId, leagueData, true),
    },
    {
      label: "PPS",
      fmt: decFormat2,
      offValue: teamData.PPS,
      offRank: rankForStat("PPS", teamId, leagueData),
      defValue: teamData.PPSOpp,
      defRank: rankForStat("PPSOpp", teamId, leagueData, true),
    },
    {
      label: "xPPS Lg",
      fmt: decFormat2,
      offValue: teamData.xPPSLg,
      offRank: rankForStat("xPPSLg", teamId, leagueData),
      defValue: teamData.xPPSLgOpp,
      defRank: rankForStat("xPPSLgOpp", teamId, leagueData, true),
    },
  ];

  const factors2 = [
    {
      label: "xOR%",
      fmt: decFormat,
      offValue: teamData.xOrPct,
      offRank: rankForStat("xOrPct", teamId, leagueData),
      defValue: teamData.xOrPctOpp,
      defRank: rankForStat("xOrPctOpp", teamId, leagueData, true),
    },
    {
      label: "OR%",
      fmt: decFormat,
      offValue: teamData.orPct,
      offRank: rankForStat("orPct", teamId, leagueData),
      defValue: teamData.orPctOpp,
      defRank: rankForStat("orPctOpp", teamId, leagueData, true),
    },
    {
      label: "xTO%",
      fmt: decFormat,
      offValue: teamData.xTovPct,
      offRank: rankForStat("xTovPct", teamId, leagueData, true),
      defValue: teamData.xTovPctOpp,
      defRank: rankForStat("xTovPctOpp", teamId, leagueData),
    },
    {
      label: "TO%",
      fmt: decFormat,
      offValue: teamData.tovPct,
      offRank: rankForStat("tovPct", teamId, leagueData, true),
      defValue: teamData.tovPctOpp,
      defRank: rankForStat("tovPctOpp", teamId, leagueData),
    },
    {
      label: "Shots/Poss",
      fmt: decFormat2,
      offValue: teamData.shotsPerPoss,
      offRank: rankForStat("shotsPerPoss", teamId, leagueData),
      defValue: teamData.shotsPerPossOpp,
      defRank: rankForStat("shotsPerPossOpp", teamId, leagueData, true),
    },
  ];

  const shotSelection = [
    {
      label: "Layup%",
      fmt: decFormat,
      offValue: teamData["layupPct"],
      offRank: rankForStat("layupPct", teamId, leagueData),
      defValue: teamData["layupPctOpp"],
      defRank: rankForStat("layupPctOpp", teamId, leagueData, true),
    },
    {
      label: "LayupA%",
      fmt: decFormat,
      offValue: teamData["layupAttPct"],
      offRank: rankForStat("layupAttPct", teamId, leagueData),
      defValue: teamData["layupAttPctOpp"],
      defRank: rankForStat("layupAttPctOpp", teamId, leagueData, true),
    },
    {
      label: "NL2%",
      fmt: decFormat,
      offValue: teamData["nl2Pct"],
      offRank: rankForStat("nl2Pct", teamId, leagueData),
      defValue: teamData["nl2PctOpp"],
      defRank: rankForStat("nl2PctOpp", teamId, leagueData, true),
    },
    {
      label: "NL2A%",
      fmt: decFormat,
      offValue: teamData["nl2AttPct"],
      offRank: rankForStat("nl2AttPct", teamId, leagueData, true),
      defValue: teamData["nl2AttPctOpp"],
      defRank: rankForStat("nl2AttPctOpp", teamId, leagueData),
    },
    {
      label: "3P%",
      fmt: decFormat,
      offValue: teamData["3pPct"],
      offRank: rankForStat("3pPct", teamId, leagueData),
      defValue: teamData["3pPctOpp"],
      defRank: rankForStat("3pPctOpp", teamId, leagueData, true),
    },
    {
      label: "3PA%",
      fmt: decFormat,
      offValue: teamData["3paPct"],
      offRank: rankForStat("3paPct", teamId, leagueData),
      defValue: teamData["3paPctOpp"],
      defRank: rankForStat("3paPctOpp", teamId, leagueData, true),
    },
  ];

  const misc = [
    {
      label: "FT%",
      fmt: decFormat,
      offValue: teamData.ftPct,
      offRank: rankForStat("ftPct", teamId, leagueData),
      defValue: teamData.ftPctOpp,
      defRank: rankForStat("ftPctOpp", teamId, leagueData, true),
    },
    {
      label: "FTA Rate",
      fmt: decFormat,
      offValue: teamData.ftaPct,
      offRank: rankForStat("ftaPct", teamId, leagueData),
      defValue: teamData.ftaPctOpp,
      defRank: rankForStat("ftaPctOpp", teamId, leagueData, true),
    },
    {
      label: "AST/FGM%",
      fmt: decFormat,
      offValue: teamData.astFgmPct,
      offRank: rankForStat("astFgmPct", teamId, leagueData),
      defValue: teamData.astFgmPctOpp,
      defRank: rankForStat("astFgmPctOpp", teamId, leagueData, true),
    },
    {
      label: "Crashers/Shot",
      fmt: decFormat2,
      offValue: teamData.crashersPerShot,
      offRank: rankForStat("crashersPerShot", teamId, leagueData),
      defValue: teamData.crashersPerShotOpp,
      defRank: rankForStat("crashersPerShotOpp", teamId, leagueData, true),
    },
    {
      label: "Zone xPPP",
      fmt: decFormat2,
      offValue: teamData.zonexPPP,
      offRank: rankForStat("zonexPPP", teamId, leagueData),
      defValue: teamData.zonexPPPOpp,
      defRank: rankForStat("zonexPPPOpp", teamId, leagueData, true),
    },
  ];

  const context = [
    {
      label: "Half-court xPPP",
      fmt: decFormat2,
      offValue: teamData.hcXPPP,
      offRank: rankForStat("hcXPPP", teamId, leagueData),
      defValue: teamData.hcXPPPOpp,
      defRank: rankForStat("hcXPPPOpp", teamId, leagueData, true),
    },
    {
      label: "Transition xPPP",
      fmt: decFormat2,
      offValue: teamData.transXPPP,
      offRank: rankForStat("transXPPP", teamId, leagueData),
      defValue: teamData.transXPPPOpp,
      defRank: rankForStat("transXPPPOpp", teamId, leagueData, true),
    },
    {
      label: "Transition Rate",
      fmt: dec100Format,
      offValue: teamData.transRate,
      offRank: rankForStat("transRate", teamId, leagueData),
      defValue: teamData.transRateOpp,
      defRank: rankForStat("transRateOpp", teamId, leagueData, true),
    },
    {
      label: "ATO xPPP",
      fmt: decFormat2,
      offValue: teamData.atoXPPP,
      offRank: rankForStat("atoXPPP", teamId, leagueData),
      defValue: teamData.atoXPPPOpp,
      defRank: rankForStat("atoXPPPOpp", teamId, leagueData, true),
    },
    {
      label: "SOB xPPP",
      fmt: decFormat2,
      offValue: teamData.sobXPPP,
      offRank: rankForStat("sobXPPP", teamId, leagueData),
      defValue: teamData.sobXPPPOpp,
      defRank: rankForStat("sobXPPPOpp", teamId, leagueData, true),
    },
    {
      label: "BOB xPPP",
      fmt: decFormat2,
      offValue: teamData.bobXPPP,
      offRank: rankForStat("bobXPPP", teamId, leagueData),
      defValue: teamData.bobXPPPOpp,
      defRank: rankForStat("bobXPPPOpp", teamId, leagueData, true),
    },
  ];

  return [factors1, factors2, shotSelection, misc, context];
}

// By default higher is better but for def stats or certain off stats lower
// numbers are better.
function rankForStat(
  stat: keyof TeamStats,
  teamId: number,
  leagueData: TeamStats[],
  reverse = false
) {
  const teamData = leagueData.find((t) => t.teamId === teamId);
  if (teamData === undefined || teamData[stat] === null) return null;

  return (
    leagueData
      .filter((l) => l[stat] !== null)
      .sort((a, b) => {
        const aVal = a[stat];
        const bVal = b[stat];

        if (aVal === bVal) return 0;

        if (reverse) {
          if (aVal === null) return 1;
          else if (bVal === null) return -1;
          return aVal - bVal;
        } else {
          if (aVal === null) return -1;
          else if (bVal === null) return 1;
          return bVal - aVal;
        }
      })
      .findIndex((l) => l.teamId === teamId) + 1
  );
}
