import React, { ReactNode } from "react";
import { Form, Row, Col } from "react-bootstrap";

import { MultiSelect } from "../core/MultiSelect";
import { BooleanInput } from "../core/BooleanInput";
import { Slider } from "../core/Slider";

export type FilterFormInputType =
  | "boolean"
  | "multiselect"
  | "number"
  | "daterange"
  | "range";

type FilterFormRange<T> = {
  type: "range";
  key2: keyof T;
  maxValue: number;
  stepSize: number;
  fmt: (v1: number, v2: number) => string;
};

type FilterFormDateRange<T> = {
  type: "daterange";
  key2: keyof T;
};

type FilterFormBoolean = {
  type: "boolean";
};

type FilterFormMultiSelect = {
  type: "multiselect";
  options: { value: string; label: string }[];
};

type FilterFormInput<T> = {
  label: string;
  key: keyof T;
} & (
  | FilterFormBoolean
  | FilterFormMultiSelect
  | FilterFormDateRange<T>
  | FilterFormRange<T>
);

export function FilterForm<T>(props: {
  filters: T;
  setFilters: (f: T) => void;
  controls: FilterFormInput<T>[][][];
}) {
  const { filters, setFilters, controls } = props;

  // Iterate through list of controls and every two will be put in a row.
  const rows = Array.from(
    { length: Math.ceil(controls.length / 2) },
    (_, i) => i
  );

  let iterator = 0;

  return (
    <Form>
      <Form.Group
        style={{
          borderBottom: "1px solid #eee",
          paddingBottom: 15,
          marginBottom: 15,
        }}
      >
        {rows.map((r) => {
          const leftCol = controls[iterator] || [];
          const rightCol = controls[iterator + 1] || [];
          iterator += 2;
          return (
            <Row key={r}>
              {[leftCol, rightCol].map((col, i) => {
                const onlyBooleanControls =
                  col.flatMap((c) => c.filter((c) => c.type !== "boolean"))
                    .length === 0;
                return (
                  <Col
                    key={i}
                    style={{
                      background: onlyBooleanControls ? undefined : "#f4f4f4",
                      borderRadius: 4,
                      margin: 4,
                      padding: 12,
                    }}
                  >
                    {col.map((c, j) => (
                      <Row key={j} style={{ marginBottom: 12 }}>
                        {c.map((c, k) => {
                          let inputElement: ReactNode = `${c.type} not implemented`;
                          if (c.type === "multiselect") {
                            inputElement = (
                              <MultiSelect
                                values={(c.options || []).map((o) => {
                                  return { value: o.value, label: o.label };
                                })}
                                selected={filters[c.key] as string[]}
                                onChange={(vals) =>
                                  setFilters({ ...filters, [c.key]: vals })
                                }
                              />
                            );
                          } else if (c.type === "boolean") {
                            inputElement = (
                              <BooleanInput
                                name={c.key as string}
                                value={boolToStringFix(
                                  filters[c.key] as boolean
                                )}
                                onChange={(val) =>
                                  setFilters({
                                    ...filters,
                                    [c.key]: stringToBoolFix(val),
                                  })
                                }
                              />
                            );
                          } else if (c.type === "daterange") {
                            inputElement = (
                              <div>
                                <Form.Control
                                  type="date"
                                  style={{
                                    width: "auto",
                                    display: "inline-block",
                                  }}
                                  value={filters[c.key] as string}
                                  onChange={(evt) => {
                                    setFilters({
                                      ...filters,
                                      [c.key]: evt.target.value,
                                    });
                                  }}
                                />
                                {" to "}
                                <Form.Control
                                  type="date"
                                  style={{
                                    width: "auto",
                                    display: "inline-block",
                                  }}
                                  value={filters[c.key2] as string}
                                  onChange={(evt) => {
                                    setFilters({
                                      ...filters,
                                      [c.key2]: evt.target.value,
                                    });
                                  }}
                                />
                              </div>
                            );
                          } else if (c.type === "range") {
                            const multiple = 1 / c.stepSize;

                            inputElement = (
                              <div>
                                <div style={{ height: 8 }}>
                                  <Slider
                                    numSteps={c.maxValue * multiple}
                                    value={{
                                      min:
                                        ((filters[c.key] as number) || 0) *
                                        multiple,
                                      max:
                                        ((filters[c.key2] as number) ||
                                          c.maxValue) * multiple,
                                    }}
                                    onChange={(v: {
                                      min: number;
                                      max: number;
                                    }) =>
                                      setFilters({
                                        ...filters,
                                        [c.key]: v.min / multiple,
                                        [c.key2]: v.max / multiple,
                                      })
                                    }
                                  />
                                </div>
                                <div>
                                  {c.fmt(
                                    (filters[c.key] as number) || 0,
                                    (filters[c.key2] as number) || c.maxValue
                                  )}
                                </div>
                              </div>
                            );
                          }
                          return (
                            <Col key={k}>
                              <Form.Label>{c.label}</Form.Label>
                              {inputElement}
                            </Col>
                          );
                        })}
                      </Row>
                    ))}
                  </Col>
                );
              })}
            </Row>
          );
        })}
      </Form.Group>
    </Form>
  );
}

// TODO(chrisbu): Remove these functions when the shot explorer is migrated to
// tRPC and no longer use strings for boolean values.
function boolToStringFix(val?: boolean) {
  if (val === undefined) return undefined;
  return val ? "1" : "0";
}

function stringToBoolFix(val?: string | number) {
  if (val === undefined) return undefined;
  return val === "1" || val === 1;
}
