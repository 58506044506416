import React, { useState, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import {
  DelimitedArrayParam,
  DelimitedNumericArrayParam,
  useQueryParams,
  withDefault,
  QueryParamConfig,
} from "use-query-params";
import { Link } from "react-router-dom";

import { Page } from "../components/core/Page";
import { Panel } from "../components/core/Panel";
import { trpc } from "../util/tRPC";
import AppContext from "../../shared/AppContext";
import {
  MultiLeagueSeasonBox,
  MultiLeagueSeasonPer100Est,
} from "../../shared/routers/TeamRouter";
import { PlayerTableCell } from "../components/core/TableCell";
import { TeamMultiLeagueSeasonBox } from "../components/team/TeamMultiLeagueSeasonBox";
import { TeamMultiLeagueSeasonBoxPer100Est } from "../components/team/TeamMultiLeagueSeasonBoxPer100Est";
import { MultiSelect } from "../components/core/MultiSelect";
import { VideoModal } from "../components/video/VideoModal";
import { ProspectsVideoClip } from "../../shared/routers/ScoutingRouter";
import { PlayerPhoto } from "../components/player/PlayerPhoto";
import { PlayerScoutRatings } from "../components/player/PlayerScoutRatings";
import {
  PlayerBio,
  PlayerMeasurements,
  PlayerModeledMeasurements,
} from "../../shared/routers/PlayerRouter";
import { UserContext } from "../UserContext";
import { PlayerCompareMeasurementsTable } from "../components/player/PlayerCompareMeasurementsTable";
import { reduceArrayToObject, groupBy } from "../../shared/util/Collections";

export function ScoutingPage() {
  const [queryParams, setQueryParams] = useQueryParams({
    teams: withDefault(DelimitedArrayParam, []) as QueryParamConfig<string[]>,
    prospectOverrides: withDefault(
      DelimitedNumericArrayParam,
      []
    ) as QueryParamConfig<number[]>,
  });

  const { prospectOverrides, teams } = queryParams;

  const { data: teamOptions } = trpc.scouting.getCollegeTeams.useQuery();

  const { data: prospects } = trpc.team.getMultiLeagueSeasonBox.useQuery({
    teamIds: teams.length ? teams.map((t) => t || "") : undefined,
    league: "ncaa",
    season: AppContext.currentSeason,
  });

  const { data: per100EstBoxes } =
    trpc.team.getMultiLeagueSeasonPer100Est.useQuery({
      teamIds: teams.length ? teams.map((t) => t || "") : undefined,
      league: "ncaa",
      season: AppContext.currentSeason,
    });

  // These are the players that, based on their scouting data, should be
  // selected by default.
  const selectedByDefaultProspectIds = new Set<number>();

  // These are the players that, after overrides are applied, are selected.
  const selectedProspectIds = new Set<number>();
  (prospects || []).forEach((p) => {
    const initiallyChecked = shouldCheckByDefault(p);
    if (initiallyChecked) {
      selectedByDefaultProspectIds.add(p.playerId);
    }
    if (initiallyChecked !== prospectOverrides.includes(p.playerId)) {
      selectedProspectIds.add(p.playerId);
    }
  });

  const selectedProspects = (prospects || []).filter((p) =>
    selectedProspectIds.has(p.playerId)
  );
  const { data: bios } = trpc.player.getPlayerBios.useQuery({
    playerIds: selectedProspects.map((p) => p.playerId.toString()),
  });

  const { data: measurements } = trpc.player.getPlayerMeasurements.useQuery({
    playerIds: selectedProspects.map((p) => p.playerId.toString()),
  });

  const { data: modeledMeasurements } =
    trpc.player.getPlayerModeledMeasurements.useQuery({
      playerIds: selectedProspects.map((p) => p.playerId.toString()),
    });

  const scoutingGuideHeader = (
    <div style={{ display: "flex", gap: 16, flexWrap: "wrap" }}>
      <div>
        <h1>Scouting Guide</h1>
        <div>Compare NCAA prospects across multiple teams.</div>
      </div>
      <div style={{ minWidth: 352 }}>
        <Form.Label>Teams</Form.Label>
        <MultiSelect
          values={(teamOptions || []).map((s) => {
            return { value: s.teamId.toString(), label: s.team };
          })}
          selected={teams.map((t) => t || "")}
          onChange={(vals) => setQueryParams({ teams: vals })}
        />
      </div>
    </div>
  );

  return (
    <Page header={{ component: scoutingGuideHeader }} title={"Scouting Guide"}>
      <>
        <HeadshotsPanels
          prospects={prospects || []}
          bios={bios || []}
          selectedTeams={(teamOptions || []).filter((t) =>
            teams.includes(t.teamId.toString())
          )}
        />
        <PlayerFilterPanel
          prospects={prospects || []}
          selectedProspectIds={selectedProspectIds}
          setSelectedProspectIds={(val) => {
            const newOverrides = (prospects || [])
              .filter(
                (p) =>
                  val.has(p.playerId) !==
                  selectedByDefaultProspectIds.has(p.playerId)
              )
              .map((p) => p.playerId);
            setQueryParams({ prospectOverrides: newOverrides });
          }}
          onReset={() => setQueryParams({ prospectOverrides: [] })}
        />
        <BoxScoresPanel
          prospects={prospects || []}
          per100EstBoxes={per100EstBoxes || []}
          selectedProspectIds={selectedProspectIds}
        />
        <MeasurementsPanel
          measurements={modeledMeasurements || []}
          bios={bios || []}
        />
        <VideoClipsPanel
          prospects={prospects || []}
          selectedProspectIds={selectedProspectIds}
        />
        <ScoutRatingsPanel bios={bios || []} measurements={measurements} />
      </>
    </Page>
  );
}

function MeasurementsPanel(props: {
  measurements: PlayerModeledMeasurements[];
  bios: PlayerBio[];
}) {
  const { measurements, bios } = props;
  return (
    <Panel header={"Measurements"}>
      <PlayerCompareMeasurementsTable
        bios={bios}
        modeledMeasurements={measurements}
        showBoxPlots={false}
      />
    </Panel>
  );
}

function ScoutRatingsPanel(props: {
  bios: PlayerBio[];
  measurements?: PlayerMeasurements[];
}) {
  const { bios, measurements } = props;
  const user = useContext(UserContext);

  const { data: ratings } = trpc.scouting.getScoutRatings.useQuery({
    user: user ? user.email.split("@")[0] || "unknown" : "unknown",
    playerIds: bios.map((b) => b.playerId.toString()),
  });

  const scoutRatingsByPlayerId = groupBy(ratings || [], (r) =>
    r.PlayerId.toString()
  );

  const measurementsByPlayerId = groupBy(measurements || [], (m) =>
    m.playerId.toString()
  );

  return (
    <Panel header={"Scout Ratings"}>
      {ratings &&
        measurements &&
        bios.map((b, i) => (
          <PlayerScoutRatings
            key={i}
            bio={b}
            data={scoutRatingsByPlayerId[b.playerId] || []}
            measurements={measurementsByPlayerId[b.playerId] || []}
          />
        ))}
    </Panel>
  );
}

function HeadshotsPanels(props: {
  selectedTeams: { team: string; teamId: number; espnId: number | null }[];
  prospects: MultiLeagueSeasonBox[];
  bios: PlayerBio[];
}) {
  const { selectedTeams, prospects, bios } = props;

  const espnIds = selectedTeams.map((t) => t.espnId || 0).filter((id) => id);

  const queries = trpc.useQueries((t) =>
    espnIds
      .filter((ei) => ei !== null)
      .map((ei) =>
        t.scouting.getHeadShotsForTeam({
          teamId: ei,
        })
      )
  );

  const headShotsByTeam = groupBy(
    queries
      .map((q) => q.data)
      .flat()
      .filter((d) => d) as {
      headshot: string | null;
      name: string;
      teamId: number;
      playerId: number;
      jerseyNumber: number;
    }[],
    (h) => h.teamId.toString()
  );

  const prospectsByTeam = groupBy(prospects, (p) => p.teamId.toString());
  const playerIdToBioMap = reduceArrayToObject(bios, (b) =>
    b.playerId.toString()
  );

  return (
    <>
      {selectedTeams.map((t) => {
        const headShotsForTeam = t.espnId
          ? headShotsByTeam[t.espnId.toString()] || []
          : [];
        const prospectsForTeam = prospectsByTeam[t.teamId.toString()] || [];

        const headShotsByJerseyNumber = reduceArrayToObject(
          headShotsForTeam,
          (h) => h.jerseyNumber.toString()
        );
        const headShotsByName = reduceArrayToObject(
          headShotsForTeam,
          (h) => h.name
        );

        return (
          <Panel
            header={`${t.team} Headshots`}
            defaultExpanded={false}
            key={t.teamId}
          >
            <div style={{ whiteSpace: "nowrap", overflowX: "auto" }}>
              {prospectsForTeam
                .sort((a, b) => a.player.localeCompare(b.player))
                .map((p, i) => {
                  const playerBio = playerIdToBioMap[p.playerId];

                  // First try using the jersey number, if thats null try by name.
                  const espnPlayer =
                    p.jerseyNumber !== null
                      ? headShotsByJerseyNumber[p.jerseyNumber]
                      : headShotsByName[p.player];

                  const espnHeadshot = espnPlayer ? espnPlayer.headshot : null;

                  // Now that we potentially have an ESPN player mapping, try
                  // and get the jersey number. We start with what is already
                  // in our system but if that is null check if the ESPN mapping
                  // has it.
                  const jerseyNumber =
                    p.jerseyNumber === null
                      ? espnPlayer === undefined
                        ? null
                        : espnPlayer.jerseyNumber
                      : p.jerseyNumber;

                  return (
                    <div
                      key={i}
                      style={{ display: "inline-block", marginRight: 8 }}
                    >
                      <PlayerPhoto
                        espnPhoto={espnHeadshot}
                        dxPhoto={espnHeadshot ? null : playerBio?.Headshot}
                      />
                      <div>
                        {jerseyNumber !== null ? `#${jerseyNumber} ` : ""}
                        {p.player}
                      </div>
                    </div>
                  );
                })}
            </div>
          </Panel>
        );
      })}
    </>
  );
}

function PlayerFilterPanel(props: {
  prospects: MultiLeagueSeasonBox[];
  selectedProspectIds: Set<number>;
  setSelectedProspectIds: (val: Set<number>) => void;
  onReset: () => void;
}) {
  const { prospects, selectedProspectIds, setSelectedProspectIds, onReset } =
    props;
  const prospectsByTeam = groupBy(prospects, (p) => p.team);
  return (
    <Panel header={"Select Prospects"} defaultExpanded={false}>
      <>
        <div
          style={{
            display: "flex",
            gap: 16,
            flexWrap: "wrap",
            marginBottom: 8,
          }}
        >
          {Object.entries(prospectsByTeam).map(([team, players], i) => {
            const teamId = players[0] && players[0].teamId;
            return (
              <div key={i}>
                <h4>
                  <Link
                    to={`/team/ml/${teamId}?season=${AppContext.currentSeason}`}
                  >
                    {team}
                  </Link>
                </h4>
                <table>
                  <thead>
                    <th></th>
                    <th></th>
                  </thead>
                  <tbody>
                    {players
                      .sort((a, b) => b.mpg - a.mpg)
                      .map((p, i) => (
                        <tr key={i}>
                          <td>
                            <Form.Check
                              checked={selectedProspectIds.has(p.playerId)}
                              onChange={() => {
                                const newSet = new Set(selectedProspectIds);
                                if (newSet.has(p.playerId)) {
                                  newSet.delete(p.playerId);
                                } else {
                                  newSet.add(p.playerId);
                                }
                                setSelectedProspectIds(newSet);
                              }}
                            />
                          </td>
                          <td>
                            <PlayerTableCell
                              id={p.playerId}
                              name={p.player}
                              useSpans={true}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            );
          })}
        </div>
        <Button onClick={onReset}>Reset</Button>
      </>
    </Panel>
  );
}

function BoxScoresPanel(props: {
  prospects: MultiLeagueSeasonBox[];
  selectedProspectIds: Set<number>;
  per100EstBoxes: MultiLeagueSeasonPer100Est[];
}) {
  const { prospects, selectedProspectIds, per100EstBoxes } = props;
  const [seasonBoxActiveTab, setSeasonBoxActiveTab] = useState("seasonBox");
  const data = prospects.filter((p) => selectedProspectIds.has(p.playerId));
  const per100Data = per100EstBoxes.filter((p) =>
    selectedProspectIds.has(p.celticsID)
  );

  const tabs = {
    onClick: (tabKey: string) => setSeasonBoxActiveTab(tabKey),
    active: seasonBoxActiveTab,
    tabs: {
      seasonBox: {
        label: "Season Box Scores",
        content: (
          <TeamMultiLeagueSeasonBox
            data={data}
            type={"perGame"}
            showTeam={true}
          />
        ),
      },
      multiLeagueSeasonBoxPer100: {
        label: "Per 100 Possessions",
        content: (
          <TeamMultiLeagueSeasonBox
            data={data}
            type={"per100Poss"}
            showTeam={true}
          />
        ),
      },
      multiLeagueSeasonBoxPer100Est: {
        label: "Per 100 Possessions Estimates",
        content: (
          <TeamMultiLeagueSeasonBoxPer100Est
            data={per100Data}
            showTeam={true}
          />
        ),
      },
    },
  };
  return <Panel tabs={tabs} />;
}

function VideoClipsPanel(props: {
  prospects: MultiLeagueSeasonBox[];
  selectedProspectIds: Set<number>;
}) {
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [playerFilter, setPlayerFilter] = useState("all");
  const [clipFilter, setClipFilter] = useState("all");
  const { prospects, selectedProspectIds } = props;
  const selectedProspects = prospects.filter((p) =>
    selectedProspectIds.has(p.playerId)
  );

  const { data } = trpc.scouting.getProspectsVideoClips.useQuery({
    playerIds: selectedProspects.map((p) => p.playerId),
  });

  const playerNameMap = new Map<number, string>();
  selectedProspects.forEach((p) => playerNameMap.set(p.playerId, p.player));

  const filteredClips = (data || [])
    .filter(
      (c) => playerFilter === "all" || c.celticsId.toString() === playerFilter
    )
    .filter((c) => {
      if (clipFilter === "all") return true;
      else if (clipFilter === "off" && c.is_offense) return true;
      else if (clipFilter === "off-shooter" && c.is_offense && c.is_shooter)
        return true;
      else if (clipFilter === "off-iso" && c.is_offense && c.is_iso)
        return true;
      else if (clipFilter === "off-post" && c.is_offense && c.is_post_up)
        return true;
      else if (clipFilter === "off-pnr-bhr" && c.is_offense && c.is_pnr_bhr)
        return true;
      else if (
        clipFilter === "off-pnr-screener" &&
        c.is_offense &&
        c.is_pnr_screener
      )
        return true;
      else if (
        clipFilter === "off-other" &&
        c.is_offense &&
        c.is_iso + c.is_post_up + c.is_pnr_bhr + c.is_pnr_screener === 0
      )
        return true;
      else if (clipFilter === "def" && c.is_defense) return true;
      else if (
        clipFilter === "def-shooter" &&
        c.is_defense &&
        c.is_defend_shooter
      )
        return true;
      else if (clipFilter === "def-iso" && c.is_defense && c.is_iso)
        return true;
      else if (clipFilter === "def-pnr-bhr" && c.is_defense && c.is_pnr_bhr)
        return true;
      else if (
        clipFilter === "def-pnr-screener" &&
        c.is_defense &&
        c.is_pnr_screener
      )
        return true;
      else if (clipFilter === "def-post" && c.is_defense && c.is_post_up)
        return true;
      else if (
        clipFilter === "def-other" &&
        c.is_defense &&
        c.is_iso +
          c.is_post_up +
          c.is_pnr_bhr +
          c.is_pnr_screener +
          c.is_defend_shooter ===
          0
      )
        return true;
      return false;
    })
    .map((c) => {
      return {
        url: c.url,
        label: `${playerNameMap.get(c.celticsId)} ${prospectVideoClipToLabel(
          c
        )}`,
      };
    });

  return (
    <Panel header={"Video Clips"}>
      <>
        <div
          style={{
            display: "flex",
            gap: 16,
            marginBottom: 8,
            flexWrap: "wrap",
          }}
        >
          <Form.Select
            style={{ width: "auto" }}
            onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => {
              const newVal = evt.target.value;
              setPlayerFilter(newVal);
            }}
          >
            <option value="all">All Prospects</option>
            <optgroup label="-----------"></optgroup>
            {selectedProspects.map((p) => (
              <option key={p.playerId} value={p.playerId.toString()}>
                {p.player}
              </option>
            ))}
          </Form.Select>
          <Form.Select
            style={{ width: "auto" }}
            onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => {
              const newVal = evt.target.value;
              setClipFilter(newVal);
            }}
          >
            <option value="all">All Clips</option>
            <optgroup label="-----------"></optgroup>
            <option value="off">All Offense</option>
            <option value="off-shooter">Shooter</option>
            <option value="off-iso">Iso</option>
            <option value="off-pnr-bhr">PNR Ballhandler</option>
            <option value="off-pnr-screener">PNR Screener</option>
            <option value="off-post">Post Up</option>
            <option value="off-other">Other</option>
            <optgroup label="-----------"></optgroup>
            <option value="def">All Defense</option>
            <option value="def-shooter">Defending Shooter</option>
            <option value="def-iso">Iso Defense</option>
            <option value="def-pnr-bhr">PNR Ballhandler Defense</option>
            <option value="def-pnr-screener">PNR Screener Defense</option>
            <option value="def-post">Post Up Defense</option>
            <option value="def-other">Other Defense</option>
          </Form.Select>
          <Button
            onClick={() => {
              setShowVideoModal(true);
            }}
            disabled={!filteredClips || filteredClips.length === 0}
          >{`Watch ${filteredClips.length} Clips`}</Button>
        </div>
        <VideoModal
          clips={filteredClips}
          title={"Scouting Guide"}
          show={showVideoModal}
          handleClose={() => setShowVideoModal(false)}
          upDownClipSkip={true}
          showSynergyEditor={false}
        />
      </>
    </Panel>
  );
}

function shouldCheckByDefault(prospect: MultiLeagueSeasonBox) {
  if (prospect.pos_estimate !== null && prospect.pos_estimate >= 4) {
    // Cs sort of get an extra point of impact so check if their draft model is
    // above 0.
    return (
      (prospect.netImpactPred && prospect.netImpactPred > 0) ||
      (prospect.selectionProbability && prospect.selectionProbability > 0.2)
    );
  } else {
    // For other players check if their draft model is above -1.
    return (
      (prospect.netImpactPred && prospect.netImpactPred > -0.01) ||
      (prospect.selectionProbability && prospect.selectionProbability > 0.2)
    );
  }
}

function prospectVideoClipToLabel(clip: ProspectsVideoClip) {
  const offDef = clip.is_offense ? "" : " Defense";
  const playType = clip.is_iso
    ? "Iso"
    : clip.is_post_up
    ? "Post Up"
    : clip.is_pnr_bhr
    ? "PNR Ballhandler"
    : clip.is_pnr_screener
    ? "PNR Screener"
    : clip.is_shooter || clip.is_defend_shooter
    ? "Shooter"
    : "Other";

  return `(${playType}${offDef})`;
}
