import React, { useState } from "react";
import { Form, Alert, Row, Col, Collapse } from "react-bootstrap";
import {
  JsonParam,
  useQueryParams,
  QueryParamConfig,
  withDefault,
  DelimitedArrayParam,
  NumberParam,
  StringParam,
} from "use-query-params";

import AppContext from "../../shared/AppContext";
import { SortingState } from "../components/core/Table";
import { Page } from "../components/core/Page";
import { Panel } from "../components/core/Panel";
import { Spinner } from "../components/core/Spinner";
import { MultiSelect } from "../components/core/MultiSelect";
import { ExpandCollapseButton } from "../components/core/ExpandCollapseButton";
import { FilterChips } from "../components/query/FilterChips";
import { PossessionExplorerResultsTable } from "../components/possessions/PossessionExplorerResultsTable";
import {
  PossessionExplorerDetails,
  RESULT_LIMIT,
} from "../components/possessions/PossessionExplorerDetails";
import { PossessionFilterForm } from "../components/possessions/PossessionFilterForm";
import { groupBy as arrGroupBy } from "../../shared/util/Collections";

import { trpc } from "../util/tRPC";
import {
  PossessionAggregate,
  PossessionFilters,
} from "../../shared/routers/PossessionRouter";
import { VideoModal } from "../components/video/VideoModal";

const VIDEO_LIMIT = 500;

const groupByMap = [
  { value: "lineup", label: "Lineup On/Off" },
  { value: "lineup5Man", label: "5 Man Lineup" },
  { value: "numBigs", label: "# of Bigs" },
  { value: "numFives", label: "# of Fives" },
  { value: "season", label: "Season" },
  { value: "isPlayoff", label: "Game Type" },
  { value: "gameString", label: "Game" },
  { value: "team", label: "Team" },
  { value: "oppTeam", label: "Opponent" },
  { value: "period", label: "Period" },
  { value: "zone", label: "Zone" },
];

const SELECTABLE_COLUMNS = [
  { value: "numPos", label: "# Poss", category: "General" },
  { value: "pace", label: "Pace", category: "General" },
  { value: "oppImpact", label: "Opponent Impact", category: "General" },
  // TODO(chrisbu): Add back this column after we debug the data issues.
  // { value: "plusMinus", label: "+/-" },
  { value: "xppp", label: "xPPP", category: "General" },
  { value: "predppp", label: "Pred. PPP", category: "General" },
  { value: "ppp", label: "PPP", category: "General" },
  { value: "delta", label: "∆", category: "General" },
  { value: "shotsPerPoss", label: "Shots/Poss", category: "General" },
  { value: "pctThree", label: "3P%", category: "Shot Making" },
  { value: "xpps", label: "xPPS", category: "Shot Making" },
  { value: "pps", label: "PPS", category: "Shot Making" },
  { value: "layupPct", label: "% Layup", category: "Shot Selection" },
  { value: "threePct", label: "% 3", category: "Shot Selection" },
  { value: "nl2Pct", label: "% Non-Layup 2", category: "Shot Selection" },
  { value: "crashersPerShot", label: "All", category: "Crashers/Shot" },
  {
    value: "interiorCrashersPerShot",
    label: "Interior",
    category: "Crashers/Shot",
  },
  {
    value: "perimCrashersPerShot",
    label: "Perimeter",
    category: "Crashers/Shot",
  },
  { value: "transitionPct", label: "Transition %", category: "Other" },
  { value: "toPct", label: "Turnover %", category: "Other" },
  { value: "xToPct", label: "xTurnover %", category: "Other" },
  { value: "orbPct", label: "ORB %", category: "Other" },
  { value: "xOrbPct", label: "xORB %", category: "Other" },
  { value: "zonePct", label: "Zone %", category: "Other" },
];

export function PossessionExplorerPage() {
  const [video, setVideo] = useState<{
    poss: PossessionAggregate;
    offDef: string;
  }>();

  const [queryParams, setQueryParams] = useQueryParams({
    sorting: JsonParam as QueryParamConfig<SortingState>,
    filters: withDefault(JsonParam, {
      season: [parseInt(AppContext.currentSeason)],
    }) as QueryParamConfig<PossessionFilters>,
    possThreshold: withDefault(NumberParam, 0),
    groupBy: withDefault(DelimitedArrayParam, ["team"]) as QueryParamConfig<
      string[]
    >,
    columns: withDefault(DelimitedArrayParam, [
      "numPos",
      "xppp",
    ]) as QueryParamConfig<string[]>,
    details: JsonParam as QueryParamConfig<{
      poss: PossessionAggregate;
      idx: number;
    }>,
    offDef: withDefault(StringParam, "Both"),
    expectedActual: withDefault(StringParam, "Both"),
    yMin: withDefault(StringParam, "1"),
    yMax: withDefault(StringParam, "1.3"),
  });
  const {
    sorting,
    filters,
    possThreshold,
    groupBy,
    columns,
    details,
    offDef,
    expectedActual,
    yMin,
    yMax,
  } = queryParams;
  const [showColumnChooser, setShowColumnChooser] = useState(true);

  const possessionAggQuery = trpc.possesion.getAggPossesions.useQuery(
    {
      limit: RESULT_LIMIT,
      filters,
      groupBy,
    },
    {
      enabled: groupBy.length > 0,
      onSuccess: (data) => {
        if (details === undefined) {
          const poss1 = data[0];
          if (poss1) {
            setQueryParams({ details: { poss: poss1, idx: 0 } });
          }
        }
      },
    }
  );

  function possessionToFilters(poss: PossessionAggregate) {
    const retObj: Record<string, unknown> = {};
    if (poss) {
      for (const [key, value] of Object.entries(poss)) {
        if (["season", "teamId", "oppTeamId", "period"].includes(key)) {
          retObj[key] = [value];
        } else if (
          ["lineup5Man", "lineup", "gameString", "isPlayoff"].includes(key)
        ) {
          retObj[key] = value;
        } else if (["zone"].includes(key)) {
          retObj[key] = value === 1 ? true : false;
        } else if (["numBigs", "numFives"].includes(key)) {
          retObj[key] = { eq: value?.toString() };
        }
      }
    }
    return retObj;
  }

  const detailFilters = {
    ...filters,
    ...(details ? possessionToFilters(details.poss) : {}),
  };

  const possessionQuery = trpc.possesion.getPossessions.useQuery({
    limit: RESULT_LIMIT,
    filters: detailFilters,
  });

  const videoFilters = Object.assign(
    {},
    filters,
    video ? possessionToFilters(video.poss) : {}
  );

  const videoQuery = trpc.possesion.getPossessionVideos.useQuery(
    {
      limit: VIDEO_LIMIT,
      filters: { ...videoFilters, offDef: video ? video.offDef : undefined },
    },
    { enabled: !!video }
  );

  let possCounter = 0;
  let chanceCounter = 0;
  const clips = videoQuery.data
    ? videoQuery.data.map((d, i) => {
        if (i > 0) {
          const lastClip = videoQuery.data[i - 1];
          const lastPossId = lastClip ? lastClip.possessionId : "";
          const lastChanceId = lastClip ? lastClip.chanceId : "";

          if (d.possessionId !== lastPossId) {
            possCounter += 1;
            chanceCounter = 0;
          } else if (d.chanceId !== lastChanceId) {
            chanceCounter += 1;
          }
        }
        return {
          label: `Possession ${possCounter + 1} - Chance ${
            chanceCounter + 1
          } - ${d.outcome}`,
          url: d.url,
        };
      })
    : [];

  const allData = possessionAggQuery.data || [];
  const filteredData = allData.filter(
    (p) => Math.max(p.numPos, p.numPosDef) >= possThreshold
  );
  const msg = `Minimum Possessions: ${possThreshold.toLocaleString()} 
    (showing ${filteredData.length.toLocaleString()} of
    ${allData.length.toLocaleString()} total rows)`;

  return (
    <Page title="Possession Explorer" header={{ text: "Possession Explorer" }}>
      <div>
        {!!clips.length && (
          <VideoModal
            title="Possession Explorer"
            show={clips.length > 0}
            clips={clips}
            handleClose={() => setVideo(undefined)}
            upDownClipSkip={true}
            showSynergyEditor={false}
          />
        )}
        <Panel header={"Filters"}>
          <div>
            <PossessionFilterForm
              filters={filters}
              onFilterChange={(f) => {
                // If the filters don't currently include anything for wowy
                // lineup but we are setting a value and it (or the 5 man lineup)
                // is not already in the group by we should add it b/c 99% of
                // the time the user is going to want to group by that after
                // setting this.
                if (
                  f.wowyLineup !== undefined &&
                  !filters.wowyLineup &&
                  !groupBy.includes("lineup") &&
                  !groupBy.includes("lineup5Man")
                ) {
                  const newGroupBy = [...groupBy, "lineup"];
                  setQueryParams({
                    filters: f,
                    details: undefined,
                    groupBy: newGroupBy,
                  });
                } else {
                  setQueryParams({ filters: f, details: undefined });
                }
              }}
            />
            <Form.Group>
              <Form.Label>Group By</Form.Label>
              <MultiSelect
                values={groupByMap}
                selected={groupBy}
                onChange={(g) => setQueryParams({ groupBy: g })}
              />
            </Form.Group>
            <Form style={{ marginTop: 12 }}>
              <div>
                <Form.Label>Select Columns</Form.Label>{" "}
                <ExpandCollapseButton
                  onClick={() => setShowColumnChooser(!showColumnChooser)}
                  expanded={showColumnChooser}
                />
              </div>
              <Collapse in={showColumnChooser}>
                <Row>
                  {Object.entries(
                    arrGroupBy(SELECTABLE_COLUMNS, (x) => x.category)
                  ).map(([cat, cols]) => {
                    return (
                      <Col key={cat} md={3}>
                        <div>
                          <Form.Label>{cat}</Form.Label>
                        </div>
                        {cols.map((sc) => {
                          return (
                            <Form.Check
                              type="checkbox"
                              key={sc.value}
                              inline
                              onChange={() => {
                                const idx = columns.findIndex(
                                  (c) => c === sc.value
                                );
                                const newColumns = [...columns];
                                if (idx === -1) {
                                  newColumns.push(sc.value);
                                } else {
                                  // Else remove the item from the array.
                                  newColumns.splice(idx, 1);
                                }
                                setQueryParams({ columns: newColumns });
                              }}
                              checked={columns.includes(sc.value)}
                              label={sc.label}
                            />
                          );
                        })}
                      </Col>
                    );
                  })}
                </Row>
              </Collapse>
            </Form>
          </div>
        </Panel>
        <Panel header={"Results"}>
          {groupBy.length === 0 ? (
            <Alert variant="danger">
              At least one group by must be selected.
            </Alert>
          ) : possessionAggQuery.data ? (
            <>
              <Row style={{ marginBottom: 8 }}>
                <Col>
                  <FilterChips
                    filters={filters}
                    deletableFilters={Object.keys(filters)}
                    onRemove={(k) => {
                      const key = k as keyof PossessionFilters;
                      // Remove this key from filters object and update.
                      const newFilters = { ...filters };
                      delete newFilters[key];
                      setQueryParams({ filters: newFilters });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>{msg}</Form.Label>
                    <Form.Range
                      min={0}
                      max={1000}
                      value={possThreshold}
                      onChange={(evt) => {
                        setQueryParams({
                          possThreshold: parseInt(evt.target.value),
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <PossessionExplorerResultsTable
                    sorting={sorting}
                    setSorting={(v) => setQueryParams({ sorting: v })}
                    setDetails={(v) => setQueryParams({ details: v })}
                    setVideo={setVideo}
                    data={filteredData}
                    groupBy={groupBy}
                    columns={columns}
                    highlightIdx={details ? details.idx : undefined}
                  />
                </Col>
              </Row>
            </>
          ) : (
            <Spinner />
          )}
        </Panel>
        <Panel header={"Details"}>
          {possessionQuery.status === "loading" && <Spinner />}
          {possessionQuery.data && (
            <PossessionExplorerDetails
              filters={detailFilters}
              deletableFilters={Object.keys(filters)}
              onDeleteFilter={(k: string) => {
                const key = k as keyof PossessionFilters;
                // Remove this key from filters object and update.
                const newFilters = { ...filters };
                delete newFilters[key];
                setQueryParams({ filters: newFilters });
              }}
              data={possessionQuery.data}
              offDef={offDef}
              setOffDef={(v) => setQueryParams({ offDef: v })}
              expectedActual={expectedActual}
              setExpectedActual={(v) => setQueryParams({ expectedActual: v })}
              yMin={yMin}
              setYMin={(v) => setQueryParams({ yMin: v })}
              yMax={yMax}
              setYMax={(v) => setQueryParams({ yMax: v })}
            />
          )}
        </Panel>
      </div>
    </Page>
  );
}
