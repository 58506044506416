import React, { useMemo, useState } from "react";

import { PlayerTableCell, SparkLineTableCell } from "../core/TableCell";
import { Table, SortingState, createColumnHelper } from "../core/Table";
import {
  decFormat,
  decFormat2,
  dec100Format,
  seFormat,
  moneyFormat,
  makePlusMinus,
  pctFormat,
} from "../../util/Format";
import { lineChartColors } from "../../constants/ColorConstants";
import { TooltipItem } from "../core/TooltipItem";
import AppContext from "../../../shared/AppContext";

const columnHelper = createColumnHelper<
  PlayerRankedStatRow & { rank: number }
>();

interface PlayerRankedStatRow {
  name: string;
  playerId: number;
  value: number;
  per36?: number;
  age?: number;
  salary?: number;
  error?: number;
  valueOverTime?: number[];
}

export function PlayerRankedStatTable(props: {
  data: PlayerRankedStatRow[];
  stat: string;
  label: string;
  season: string;
}) {
  const { label, stat, season } = props;
  const data = props.data.map((d, i) => {
    return { ...d, rank: i + 1 };
  });

  const impactStat =
    stat === "netImpact" || stat === "offImpact" || stat === "defImpact";

  const [sorting, setSorting] = useState<SortingState>();

  function impactDelta(row: PlayerRankedStatRow) {
    const valueOverTimeArr = row.valueOverTime;
    if (!valueOverTimeArr) return 0;
    const firstVal = valueOverTimeArr[0];
    const lastVal = valueOverTimeArr[valueOverTimeArr.length - 1];
    if (firstVal === undefined || lastVal === undefined) return 0;
    return lastVal - firstVal;
  }

  const columns = useMemo(
    () => [
      columnHelper.accessor("rank", {
        header: () => "Rank",
      }),
      columnHelper.accessor("name", {
        header: () => "Player",
        cell: (info) => (
          <PlayerTableCell
            id={info.row.original.playerId}
            name={info.getValue()}
          />
        ),
      }),
      columnHelper.accessor("value", {
        header: () => label,
        cell: (info) => {
          const value = info.getValue();
          if (
            stat.indexOf("pct") !== -1 ||
            stat === "crashRate" ||
            stat === "actionRoleRate"
          ) {
            return pctFormat(value);
          }
          if (
            stat === "netImpact" ||
            stat === "offImpact" ||
            stat === "defImpact"
          ) {
            const stdErr = info.row.original.error;
            return `${dec100Format(value)} ${
              stdErr && seFormat(stdErr * 1.96 * 100)
            }`;
          } else if (stat === "ppp") {
            return decFormat2(value);
          }
          return decFormat(value);
        },
      }),
      columnHelper.accessor("per36", {
        header: () => "Per 36 Min",
        cell: (info) => {
          const val = info.getValue();
          if (val === undefined) return null;
          return decFormat(val);
        },
      }),
      columnHelper.accessor((row) => impactDelta(row), {
        id: "spark",
        header: "Last 50",
        cell: (info) => {
          const valueOverTime = info.row.original.valueOverTime;
          if (!valueOverTime || valueOverTime.length === 0) return null;

          // For now we'll say anything greater than .5 of impact change is significant
          // and gets a red/green color.
          const change = impactDelta(info.row.original);
          let color = "gray";
          if (Math.abs(change) > 0.005) {
            const val1 = valueOverTime[0];
            const val2 = valueOverTime[valueOverTime.length - 1];
            if (val1 !== undefined && val2 !== undefined) {
              color = val1 < val2 ? lineChartColors.green : lineChartColors.red;
            }
          }

          return (
            <TooltipItem
              noListItem={true}
              arrow={false}
              tooltip={`${makePlusMinus(dec100Format)(change)} ${label}`}
            >
              <div style={{ textAlign: "center" }}>
                <SparkLineTableCell
                  data={valueOverTime}
                  color={color}
                  minYDomain={0.01}
                />
              </div>
            </TooltipItem>
          );
        },
      }),
      columnHelper.accessor("salary", {
        header: () => "Salary",
        cell: (info) => moneyFormat(info.getValue() || null),
      }),
      columnHelper.accessor("age", {
        header: () => "Age",
        cell: (info) => decFormat(info.getValue() || null),
      }),
    ],
    [stat, label]
  );

  const hiddenColumns = {
    spark: !!(impactStat && season === AppContext.currentSeason),
    salary: !!(impactStat && season === AppContext.currentSeason),
    age: !!(impactStat && season === AppContext.currentSeason),
    per36: !!data.some((d) => d.per36),
  };

  return (
    <Table
      data={data}
      columns={columns}
      sorting={sorting}
      setSorting={setSorting}
      autoWidth={true}
      center={true}
      showRowIndex={false}
      hiddenColumns={hiddenColumns}
    />
  );
}
